import '@/plugins/axios';
import router from '@/router/index';

const api = '/platform/api/v1/customers/';

export default {
  state: {
    customerList: {},
    customerShort: [],
    customer: {},
    customerDevices: [],
    customerBookings: { pages: 0, result: [], total: 0 },
    customerPayments: { pages: 0, result: [], total: 0 },
    customerMokafaa: { pages: 0, result: [], total: 0 },
    customerAnb: { pages: 0, result: [], total: 0 },
    customerTransaction: { pages: 0, result: [], total: 0 },
    customerReview: { pages: 0, result: [], total: 0 },
  },
  actions: {
    getCustomerList(context, { search, page, sort }) {
      if (!!search || page > 1 || !!sort.length) {
        if (
          `${router.app._route.path}${!!sort.length ? `?sort=${sort}&` : '?'}${!!search ? `src=${search}&` : ''}page=${page || 1}` !=
          router.app._route.fullPath
        ) {
          router.push(
            `${router.app._route.path}${!!sort.length ? `?sort=${sort}&` : '?'}${!!search ? `src=${search}&` : ''}page=${page || 1}`
          );
        }
        const sortValue = [];
        for (var i = 0; i < sort.length; i++) {
          if (i == 0) {
            sortValue.push(`?sort=${sort[i]}&`);
          } else {
            sortValue.push(`sort=${sort[i]}&`);
          }
        }
        return axios.get(`${api}${sortValue.join('') || '?'}${!!search ? `src=${search}&` : ''}page=${page - 1 || 0}`).then((res) => {
          context.commit('GET_CUSTOMERLIST', res.data);
        });
      } else {
        if (router.app._route.path != router.app._route.fullPath) {
          router.push(`${router.app._route.path}`);
        }
        return axios.get(`${api}?page=${page - 1 || 0}`).then((res) => {
          context.commit('GET_CUSTOMERLIST', res.data);
        });
      }
    },
    setCustomerList: (context, payload) => {
      context.commit('GET_CUSTOMERLIST', payload);
    },
    getCustomerShort(context, payload) {
      return axios.get(`${api}short/${payload ? `?src=${payload}` : ''}`).then((res) => {
        context.commit('GET_CUSTOMERSHORT', res.data);
      });
    },
    setCustomerShort(context) {
      context.commit('GET_CUSTOMERSHORT', []);
    },
    getCustomerItem(context) {
      return axios.get(`${api}${router.app._route.params.customerId}`).then((res) => {
        context.commit('GET_CUSTOMER', res.data);
      });
    },
    setCustomerItem: (context) => {
      context.commit('GET_CUSTOMER', {});
      context.commit('GET_CUSTOMERDEVICES', []);
      context.commit('GET_CUSTOMERBOOKINGS', { pages: 0, result: [], total: 0 });
      context.commit('GET_CUSTOMERPAYMENTS', { pages: 0, result: [], total: 0 });
      context.commit('GET_CUSTOMERMOKAFAA', { pages: 0, result: [], total: 0 });
      context.commit('GET_CUSTOMERANB', { pages: 0, result: [], total: 0 });
      context.commit('GET_CUSTOMERTRANSACTION', { pages: 0, result: [], total: 0 });
      context.commit('GET_CUSTOMERREVIEW', { pages: 0, result: [], total: 0 });
    },
    getCustomerItemDevices(context) {
      return axios.get(`${api}${router.app._route.params.customerId}/devices/`).then((res) => {
        context.commit('GET_CUSTOMERDEVICES', res.data);
      });
    },
    getCustomerItemBookings(context, payload) {
      return axios.get(`/platform/api/v1/bookings/?customer=${router.app._route.params.customerId}&page=${payload - 1}`).then((res) => {
        context.commit('GET_CUSTOMERBOOKINGS', res.data);
      });
    },
    getCustomerItemPayments(context, payload) {
      return axios.get(`/platform/api/v1/payments/?customer=${router.app._route.params.customerId}&page=${payload - 1}`).then((res) => {
        context.commit('GET_CUSTOMERPAYMENTS', res.data);
      });
    },
    getCustomerItemMokafaa(context, payload) {
      return axios.get(`/platform/api/v1/mokafaa/?customer=${router.app._route.params.customerId}&page=${payload - 1}`).then((res) => {
        context.commit('GET_CUSTOMERMOKAFAA', res.data);
      });
    },
    getCustomerItemAnb(context, payload) {
      return axios.get(`/platform/api/v1/anb/?customer=${router.app._route.params.customerId}&page=${payload - 1}`).then((res) => {
        context.commit('GET_CUSTOMERANB', res.data);
      });
    },
    getCustomerItemTransactions(context, payload) {
      return axios
        .get(`/platform/api/v1/dw-transactions/?customer=${router.app._route.params.customerId}&page=${payload - 1}`)
        .then((res) => {
          context.commit('GET_CUSTOMERTRANSACTION', res.data);
        });
    },
    getCustomerItemReviews(context, payload) {
      return axios.get(`/platform/api/v1/reviews/?customer=${router.app._route.params.customerId}&page=${payload - 1}`).then((res) => {
        context.commit('GET_CUSTOMERREVIEW', res.data);
      });
    },
  },
  mutations: {
    GET_CUSTOMERLIST: (state, payload) => {
      state.customerList = payload;
      return state.customerList;
    },
    GET_CUSTOMERSHORT: (state, payload) => {
      state.customerShort = payload;
      return state.customerShort;
    },
    GET_CUSTOMER: (state, payload) => {
      state.customer = payload;
      return state.customer;
    },
    GET_CUSTOMERDEVICES: (state, payload) => {
      state.customerDevices = payload;
      return state.customerDevices;
    },
    GET_CUSTOMERBOOKINGS: (state, payload) => {
      state.customerBookings = payload;
      return state.customerBookings;
    },
    GET_CUSTOMERPAYMENTS: (state, payload) => {
      state.customerPayments = payload;
      return state.customerPayments;
    },
    GET_CUSTOMERMOKAFAA: (state, payload) => {
      state.customerMokafaa = payload;
      return state.customerMokafaa;
    },
    GET_CUSTOMERANB: (state, payload) => {
      state.customerAnb = payload;
      return state.customerAnb;
    },
    GET_CUSTOMERTRANSACTION: (state, payload) => {
      state.customerTransaction = payload;
      return state.customerTransaction;
    },
    GET_CUSTOMERREVIEW: (state, payload) => {
      state.customerReview = payload;
      return state.customerReview;
    },
  },
  getters: {
    customerList(state) {
      return state.customerList;
    },
    customerShort(state) {
      return state.customerShort;
    },
    customer(state) {
      return state.customer;
    },
    customerDevices(state) {
      return state.customerDevices;
    },
    customerBookings(state) {
      return state.customerBookings;
    },
    customerPayments(state) {
      return state.customerPayments;
    },
    customerMokafaa(state) {
      return state.customerMokafaa;
    },
    customerAnb(state) {
      return state.customerAnb;
    },
    customerTransaction(state) {
      return state.customerTransaction;
    },
    customerReview(state) {
      return state.customerReview;
    },
  },
};
