import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin', 'manager', 'marketing', 'agent', 'trip_organizer', 'product_vendor'],
      },
      component: () => import('@/views/Home.vue'),
    },
    {
      path: '/vendor/general',
      name: 'Vendor general',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['product_vendor'],
      },
      component: () => import('@/views/VendorGeneral.vue'),
    },
    {
      path: '/vendor/delivery',
      name: 'Vendor delivery',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['product_vendor'],
      },
      component: () => import('@/views/VendorDelivery.vue'),
    },
    {
      path: '/vendor/products',
      name: 'Vendor products',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['product_vendor'],
      },
      component: () => import('@/views/VendorProductsList.vue'),
    },
    {
      path: '/vendor/products/:vendorId',
      name: 'Vendor product',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['product_vendor'],
      },
      component: () => import('@/views/VendorProduct.vue'),
    },
    {
      path: '/vendor/orders',
      name: 'Vendor orders',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['product_vendor'],
      },
      component: () => import('@/views/VendorOrdersList.vue'),
    },
    {
      path: '/vendor/orders/:orderId',
      name: 'Vendor order',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['product_vendor'],
      },
      component: () => import('@/views/VendorOrder.vue'),
    },
    {
      path: '/profile',
      name: 'Profile',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin', 'manager', 'marketing', 'agent', 'trip_organizer', 'product_vendor'],
      },
      component: () => import('@/views/Profile.vue'),
    },
    {
      path: '/staff',
      name: 'Staff Accounts',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin'],
      },
      component: () => import('@/views/StaffList.vue'),
    },
    {
      path: '/staff/:staffId',
      name: 'Staff Account',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin'],
      },
      component: () => import('@/views/Staff.vue'),
    },
    {
      path: '/gift-voucher-offers',
      name: 'Voucher offers',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin', 'manager', 'marketing'],
      },
      component: () => import('@/views/VoucherOffersList.vue'),
    },
    {
      path: '/gift-voucher-offers/:voucherOfferId',
      name: 'Voucher offer',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin', 'manager', 'marketing'],
      },
      component: () => import('@/views/VoucherOffer.vue'),
    },
    {
      path: '/gift-vouchers',
      name: 'Vouchers',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin', 'manager', 'marketing'],
      },
      component: () => import('@/views/VouchersList.vue'),
    },
    {
      path: '/gift-vouchers/:voucherId',
      name: 'Voucher',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin', 'manager', 'marketing'],
      },
      component: () => import('@/views/Voucher.vue'),
    },
    {
      path: '/gift-vendors',
      name: 'Gift vendors',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin', 'manager'],
      },
      component: () => import('@/views/VendorsList.vue'),
    },
    {
      path: '/gift-vendors/:vendorId/:tab',
      name: 'Gift vendor',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin', 'manager'],
      },
      component: () => import('@/views/Vendor.vue'),
    },
    {
      path: '/vat',
      name: 'VAT',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin'],
      },
      component: () => import('@/views/Vat.vue'),
    },
    {
      path: '/trips',
      name: 'Trips',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin', 'manager', 'agent', 'trip_organizer'],
      },
      component: () => import('@/views/TripsList.vue'),
    },
    {
      path: '/trip-new',
      name: 'Create new trip',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin', 'manager', 'agent'],
      },
      component: () => import('@/views/TripNew.vue'),
    },
    {
      path: '/trips/:tripId/:tab',
      name: 'Trip',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin', 'manager', 'agent', 'trip_organizer'],
      },
      component: () => import('@/views/Trip.vue'),
    },
    {
      path: '/customers',
      name: 'Customers',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin'],
      },
      component: () => import('@/views/CustomerList.vue'),
    },
    {
      path: '/customers/:customerId',
      name: 'Customer Account',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin'],
      },
      component: () => import('@/views/Customer.vue'),
    },
    {
      path: '/customer-requests',
      name: 'Customer requests',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin', 'manager', 'agent'],
      },
      component: () => import('@/views/CustomerRequestList.vue'),
    },
    {
      path: '/customer-requests/:callbackId',
      name: 'Customer request',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin', 'manager', 'agent'],
      },
      component: () => import('@/views/CustomerRequest.vue'),
    },
    {
      path: '/trips-requests',
      name: 'Trips requests',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin', 'manager', 'agent'],
      },
      component: () => import('@/views/RequestsList.vue'),
    },
    {
      path: '/trips-requests/:requestsId',
      name: 'Trip request',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin', 'manager', 'agent'],
      },
      component: () => import('@/views/Requests.vue'),
    },
    {
      path: '/experiences',
      name: 'Experiences',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin', 'manager', 'agent'],
      },
      component: () => import('@/views/ExperiencesList.vue'),
    },
    {
      path: '/experience-new',
      name: 'Create new experience',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin', 'manager', 'agent'],
      },
      component: () => import('@/views/ExperienceNew.vue'),
    },
    {
      path: '/experiences/:expId/:tab',
      name: 'Experience',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin', 'manager', 'agent'],
      },
      component: () => import('@/views/Experience.vue'),
    },
    {
      path: '/experts',
      name: 'Experts',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin', 'manager', 'agent'],
      },
      component: () => import('@/views/ExpertList.vue'),
    },
    {
      path: '/experts/:expertId/:tab',
      name: 'Expert',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin', 'manager', 'agent'],
      },
      component: () => import('@/views/Expert.vue'),
    },
    {
      path: '/invitation',
      name: 'Customer invitations',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin', 'manager', 'agent'],
      },
      component: () => import('@/views/InvitationList.vue'),
    },
    {
      path: '/invitation/:invitationId',
      name: 'Customer invitation',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin', 'manager', 'agent'],
      },
      component: () => import('@/views/Invitation.vue'),
    },
    {
      path: '/trip-bookings',
      name: 'Trip bookings',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin', 'manager', 'agent', 'trip_organizer'],
      },
      component: () => import('@/views/BookingList.vue'),
    },
    {
      path: '/trip-bookings/:bookingId/:tab',
      name: 'Trip booking',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin', 'manager', 'agent', 'trip_organizer'],
      },
      component: () => import('@/views/Booking.vue'),
    },
    {
      path: '/experience-bookings',
      name: 'Experience bookings',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin', 'manager', 'agent'],
      },
      component: () => import('@/views/BookingExperienceList.vue'),
    },
    {
      path: '/experience-bookings/:bookingId/:tab',
      name: 'Experience booking',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin', 'manager', 'agent'],
      },
      component: () => import('@/views/BookingExperience.vue'),
    },
    {
      path: '/payments',
      name: 'Payments',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin', 'manager'],
      },
      component: () => import('@/views/PaymentsList.vue'),
    },
    {
      path: '/payments/:paymentsId',
      name: 'Payment',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin', 'manager'],
      },
      component: () => import('@/views/Payment.vue'),
    },
    {
      path: '/mokafaa',
      name: 'Mokafaa transactions',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin', 'manager'],
      },
      component: () => import('@/views/MokafaaList.vue'),
    },
    {
      path: '/mokafaa/:mokafaaId',
      name: 'Mokafaa transaction',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin', 'manager'],
      },
      component: () => import('@/views/Mokafaa.vue'),
    },
    {
      path: '/anb',
      name: 'ANB transactions',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin', 'manager'],
      },
      component: () => import('@/views/AnbList.vue'),
    },
    {
      path: '/anb/:anbId',
      name: 'ANB transaction',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin', 'manager'],
      },
      component: () => import('@/views/Anb.vue'),
    },
    {
      path: '/digital-wallet',
      name: 'Digital Wallet transactions',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin', 'manager'],
      },
      component: () => import('@/views/WalletList.vue'),
    },
    {
      path: '/digital-wallet/:walletId',
      name: 'Digital Wallet transaction',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin', 'manager'],
      },
      component: () => import('@/views/Wallet.vue'),
    },
    {
      path: '/announcements',
      name: 'Announcements',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin', 'marketing'],
      },
      component: () => import('@/views/AnnouncementsList.vue'),
    },
    {
      path: '/announcements-new',
      name: 'Announcements new',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin', 'marketing'],
      },
      component: () => import('@/views/AnnouncementsNew.vue'),
    },
    {
      path: '/announcements/:announcementsId',
      name: 'Announcement',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin', 'marketing'],
      },
      component: () => import('@/views/Announcements.vue'),
    },
    {
      path: '/refill-codes',
      name: 'Digital Wallet refill codes',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin'],
      },
      component: () => import('@/views/RefillCodesList.vue'),
    },
    {
      path: '/refill-codes-new',
      name: 'Digital Wallet refill code new',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin'],
      },
      component: () => import('@/views/RefillCodeNew.vue'),
    },
    {
      path: '/refill-codes/:refillId',
      name: 'Digital Wallet refill code',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin'],
      },
      component: () => import('@/views/RefillCode.vue'),
    },
    {
      path: '/trip-discount-codes',
      name: 'Discount codes',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin'],
      },
      component: () => import('@/views/DiscountList.vue'),
    },
    {
      path: '/trip-discount-codes-new',
      name: 'Discount code new',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin'],
      },
      component: () => import('@/views/DiscountNew.vue'),
    },
    {
      path: '/trip-discount-codes/:discountId',
      name: 'Discount code',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin'],
      },
      component: () => import('@/views/Discount.vue'),
    },
    {
      path: '/promotions',
      name: 'Promotions',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin', 'manager', 'marketing'],
      },
      component: () => import('@/views/PromotionsList.vue'),
    },
    {
      path: '/promotion-new',
      name: 'Promotion new',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin', 'manager', 'marketing'],
      },
      component: () => import('@/views/PromotionNew.vue'),
    },
    {
      path: '/promotions/:promotionId/:tab',
      name: 'Promotion',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin', 'manager', 'marketing'],
      },
      component: () => import('@/views/Promotion.vue'),
    },
    {
      path: '/new-promo-code/:promotionId',
      name: 'Create new promo code',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin', 'manager', 'marketing'],
      },
      component: () => import('@/views/PromoCodeNew.vue'),
    },
    {
      path: '/saudi-surprise-create',
      name: 'Create question',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin', 'manager', 'agent'],
      },
      component: () => import('@/views/CreateQuestion.vue'),
    },
    {
      path: '/saudi-surprise',
      name: 'Saudi Surprise',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin', 'manager', 'agent'],
      },
      component: () => import('@/views/ContentList.vue'),
    },
    {
      path: '/saudi-surprise/:contentId',
      name: 'Edit question',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin', 'manager', 'agent'],
      },
      component: () => import('@/views/ViweQuestion.vue'),
    },
    {
      path: '/accommodations-create',
      name: 'Create accommodation',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin', 'manager', 'agent'],
      },
      component: () => import('@/views/AccommodationNew.vue'),
    },
    {
      path: '/accommodations',
      name: 'Content accommodation',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin', 'manager', 'agent'],
      },
      component: () => import('@/views/AccommodationList.vue'),
    },
    {
      path: '/accommodations/:accommodationId/:tab',
      name: 'Edit accommodation',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin', 'manager', 'agent'],
      },
      component: () => import('@/views/Accommodation.vue'),
    },
    {
      path: '/traveler-types',
      name: 'Content traveler types',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin', 'manager'],
      },
      component: () => import('@/views/TravelerTypeList.vue'),
    },
    {
      path: '/trip-activities',
      name: 'Content trip activities',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin', 'manager'],
      },
      component: () => import('@/views/TripActivitiesList.vue'),
    },
    {
      path: '/trip-groups',
      name: 'Trip groups',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin', 'manager'],
      },
      component: () => import('@/views/TripGroupsList.vue'),
    },
    {
      path: '/trip-groups/:tripGroupsId',
      name: 'Trip group',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin', 'manager'],
      },
      component: () => import('@/views/TripGroups.vue'),
    },
    {
      path: '/inspirations',
      name: 'Content inspirations',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin', 'manager'],
      },
      component: () => import('@/views/InspirationsList.vue'),
    },
    {
      path: '/reviews',
      name: 'Reviews',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin', 'manager', 'marketing'],
      },
      component: () => import('@/views/ReviewsList.vue'),
    },
    {
      path: '/settings/:tab',
      name: 'Settings',
      meta: {
        layout: 'Main',
        auth: true,
        access: ['admin'],
      },
      component: () => import('@/views/Settings.vue'),
    },
    {
      path: '/login',
      name: 'Login',
      meta: {
        layout: 'Empty',
      },
      component: () => import('@/views/Login.vue'),
    },
    {
      path: '/blocked',
      name: 'Blocked user',
      meta: {
        layout: 'Empty',
      },
      component: () => import('@/views/Blocked.vue'),
    },
    {
      path: '/forgot',
      name: 'Forgot password',
      meta: {
        layout: 'Empty',
      },
      component: () => import('@/views/Forgot.vue'),
    },
    {
      path: '/recovery',
      name: 'Recovery password',
      meta: {
        layout: 'Empty',
      },
      component: () => import('@/views/Recovery.vue'),
    },
    {
      path: '/recovery-error',
      name: 'Password update failed',
      meta: {
        layout: 'Empty',
      },
      component: () => import('@/views/RecoveryError.vue'),
    },
    {
      path: '/recovery-password-already',
      name: 'Password already used',
      meta: {
        layout: 'Empty',
      },
      component: () => import('@/views/RecoveryAlready.vue'),
    },
    {
      path: '/expired-confirmation',
      name: 'Email confirmation link expired ',
      meta: {
        layout: 'Empty',
      },
      component: () => import('@/views/ExpiredEmail.vue'),
    },
    {
      path: '/expired-password',
      name: 'Password update link expired',
      meta: {
        layout: 'Empty',
      },
      component: () => import('@/views/ExpiredPass.vue'),
    },
    {
      path: '/sign-in-limit',
      name: 'Sign-in attempt limit ',
      meta: {
        layout: 'Empty',
      },
      component: () => import('@/views/Limit.vue'),
    },
    {
      path: '/confirm-email',
      name: 'Confirmation email',
      meta: {
        layout: 'Empty',
      },
      component: () => import('@/views/EmailConfirm.vue'),
    },
    {
      path: '/email-confirmation',
      name: 'Email confirmation',
      meta: {
        layout: 'Empty',
      },
      component: () => import('@/views/ConfirmEmail.vue'),
    },
    {
      path: '/confirmation-error',
      name: 'Confirmation error',
      meta: {
        layout: 'Empty',
      },
      component: () => import('@/views/ConfirmError.vue'),
    },
    {
      path: '/confirmation-email-already',
      name: 'Confirmation email already',
      meta: {
        layout: 'Empty',
      },
      component: () => import('@/views/ConfirmAlready.vue'),
    },
    {
      path: '*',
      redirect: { name: 'Home' },
    },
  ],
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  const currentUser = localStorage.getItem('jwt_token');
  const requireAuth = to.matched.some((record) => record.meta.auth);
  const role = localStorage.getItem('role');
  const access = requireAuth && role ? !to.matched.some((record) => record.meta.access.find((i) => i == role)) : null;

  if (requireAuth && !currentUser) {
    next({ name: 'Login', query: { from: to.fullPath } });
  } else if (access) {
    next('/');
  } else {
    next();
  }
});

export default router;
