import Vue from 'vue';
import Vuex from 'vuex';
import common from './modules/common';
import statistic from './modules/statistic';
import auth from './modules/auth';
import profile from './modules/profile';
import staff from './modules/staff';
import customer from './modules/customer';
import trips from './modules/trips';
import requests from './modules/requests';
import invitation from './modules/invitation';
import booking from './modules/booking';
import payments from './modules/payments';
import announcements from './modules/announcements';
import mokafaa from './modules/mokafaa';
import wallet from './modules/wallet';
import refillCodes from './modules/refillCodes';
import discountCodes from './modules/discountCodes';
import promotion from './modules/promotion';
import settings from './modules/settings';
import experts from './modules/experts';
import experiences from './modules/experiences';
import accommodations from './modules/accommodations';
import callback from './modules/callback';
import bookingExperiences from './modules/bookingExperiences';
import tripActivities from './modules/tripActivities';
import inspirations from './modules/inspirations';
import reviews from './modules/reviews';
import tripsGroups from './modules/tripsGroups';
import vendors from './modules/vendors';
import vendorProducts from './modules/vendorProducts';
import vendorOrders from './modules/vendorOrders';
import vouchers from './modules/vouchers';
import voucherOffers from './modules/voucherOffers';
import anb from './modules/anb';
Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    common,
    statistic,
    auth,
    profile,
    staff,
    customer,
    trips,
    requests,
    invitation,
    booking,
    payments,
    announcements,
    mokafaa,
    wallet,
    refillCodes,
    discountCodes,
    promotion,
    settings,
    experts,
    experiences,
    accommodations,
    callback,
    bookingExperiences,
    tripActivities,
    inspirations,
    reviews,
    tripsGroups,
    vendors,
    vendorProducts,
    vendorOrders,
    vouchers,
    voucherOffers,
    anb,
  },
});
