const api = '/platform/api/v1/';

export default {
  state: {
    link: '',
    countryList: [],
    citysList: [],
    cityList: [],
    cityListTwo: [],
    tripRequestList: [],
  },
  actions: {
    setLink: (context, payload) => {
      context.commit('GET_LINK', payload);
    },
    getCountryList(context) {
      return axios.get(`${api}countries/`).then((res) => {
        context.commit('GET_COUNTRY', res.data);
      });
    },
    setCountryList: (context, payload) => {
      context.commit('GET_COUNTRY', payload);
    },
    getCityList(context, payload) {
      return axios.get(`${api}countries/${payload}/cities/`, payload).then((res) => {
        context.commit('GET_CITY', res.data);
      });
    },
    getCityListTwo(context, payload) {
      return axios.get(`${api}countries/${payload}/cities/`, payload).then((res) => {
        context.commit('GET_CITYTWO', res.data);
      });
    },
    setCityList: (context) => {
      context.commit('GET_CITYS', []);
      context.commit('GET_CITY', []);
      context.commit('GET_CITYTWO', []);
    },
    getTripRequestList(context, payload) {
      return axios.get(`${api}trips/short/?src=${payload}`).then((res) => {
        context.commit('GET_TRIPREQUEST', res.data);
      });
    },
    setCitysList: (context, payload) => {
      context.commit('GET_CITYS', payload);
    },
    setTripRequestList: (context) => {
      context.commit('GET_TRIPREQUEST', []);
    },
  },
  mutations: {
    GET_LINK: (state, payload) => {
      state.link = payload;
      return state.link;
    },
    GET_COUNTRY: (state, payload) => {
      state.countryList = payload;
      return state.countryList;
    },
    GET_CITYS: (state, payload) => {
      state.citysList = payload;
      return state.citysList;
    },
    GET_CITY: (state, payload) => {
      state.cityList = payload;
      return state.cityList;
    },
    GET_CITYTWO: (state, payload) => {
      state.cityListTwo = payload;
      return state.cityListTwo;
    },
    GET_TRIPREQUEST: (state, payload) => {
      state.tripRequestList = payload;
      return state.tripRequestList;
    },
  },
  getters: {
    link(state) {
      return state.link;
    },
    countryList(state) {
      return state.countryList;
    },
    citysList(state) {
      return state.citysList;
    },
    cityList(state) {
      return state.cityList;
    },
    cityListTwo(state) {
      return state.cityListTwo;
    },
    tripRequestList(state) {
      return state.tripRequestList;
    },
  },
};
