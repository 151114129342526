import Vue from 'vue';
import './plugins/axios';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import VieMeta from 'vue-meta';
import titlePlugin from '@/plugins/title.plugin';
import VueClipboard from 'vue-clipboard2';
import Notifications from '@/components/NotificationPlugin';
import VCalendar from 'v-calendar';

Vue.config.productionTip = false;

Vue.use(VieMeta);
Vue.use(titlePlugin);
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);
Vue.use(Notifications);
Vue.use(VCalendar, {
  componentPrefix: 'vc',
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
