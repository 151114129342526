import '@/plugins/axios';
import router from '@/router/index';

const api = '/platform/api/v1/anb/';

export default {
  state: {
    anbList: {},
    anb: { customer: {} },
  },
  actions: {
    getAnbList(context, { search, page, sort, status, customer, from, to }) {
      if (!!search || page > 1 || !!sort.length || status != 'all' || customer != 'all' || !!from || !!to) {
        if (
          `${router.app._route.path}${customer != 'all' ? `?customer=${customer}&` : '?'}${status != 'all' ? `status=${status}&` : ''}${
            !!from ? `date_from=${from}&` : ''
          }${!!to ? `date_to=${to}&` : ''}${!!sort.length ? `sort=${sort}&` : ''}${!!search ? `src=${search}&` : ''}page=${page || 1}` !=
          router.app._route.fullPath
        ) {
          router.push(
            `${router.app._route.path}${customer != 'all' ? `?customer=${customer}&` : '?'}${status != 'all' ? `status=${status}&` : ''}${
              !!from ? `date_from=${from}&` : ''
            }${!!to ? `date_to=${to}&` : ''}${!!sort.length ? `sort=${sort}&` : ''}${!!search ? `src=${search}&` : ''}page=${page || 1}`
          );
        }
        const sortValue = [];
        for (var i = 0; i < sort.length; i++) {
          sortValue.push(`sort=${sort[i]}&`);
        }
        return axios
          .get(
            `${api}${customer != 'all' ? `?customer=${customer}&` : '?'}${status != 'all' ? `status=${status}&` : ''}${
              !!from ? `date_from=${from}&` : ''
            }${!!to ? `date_to=${to}&` : ''}${sortValue.join('') || ''}${!!search ? `src=${search}&` : ''}page=${page - 1 || 0}`
          )
          .then((res) => {
            context.commit('GET_ANBLIST', res.data);
          });
      } else {
        if (router.app._route.path != router.app._route.fullPath) {
          router.push(`${router.app._route.path}`);
        }
        return axios.get(`${api}`).then((res) => {
          context.commit('GET_ANBLIST', res.data);
        });
      }
    },
    setAnbList: (context) => {
      context.commit('GET_ANBLIST', {});
    },
    getAnbItem(context) {
      return axios.get(`${api}${router.app._route.params.anbId}`).then((res) => {
        context.commit('GET_ANB', res.data);
      });
    },
    setAnbItem: (context) => {
      context.commit('GET_ANB', { customer: {} });
    },
  },
  mutations: {
    GET_ANBLIST: (state, payload) => {
      state.anbList = payload;
      return state.anbList;
    },
    GET_ANB: (state, payload) => {
      state.anb = payload;
      return state.anb;
    },
  },
  getters: {
    anbList(state) {
      return state.anbList;
    },
    anb(state) {
      return state.anb;
    },
  },
};
