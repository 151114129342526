import '@/plugins/axios';

const api = '/platform/api/v1/trip-activities/';

export default {
  state: {
    tripActivities: [],
  },
  actions: {
    getTripActivities(context) {
      return axios.get(`${api}`).then((res) => {
        context.commit('GET_TRIPACTIVITIES', res.data);
      });
    },
    setTripActivities(context) {
      context.commit('GET_TRIPACTIVITIES', []);
    },
    createTripActivities(context, payload) {
      return axios.post(`${api}`, payload);
    },
    getTripActivitiesItem(context, payload) {
      return axios.get(`${api}${payload}`);
    },
    updateTripActivitiesItem(context, { id, data }) {
      return axios.put(`${api}${id}`, data);
    },
    removeTripActivitiesItem(context, payload) {
      return axios.delete(`${api}${payload}`);
    },
  },
  mutations: {
    GET_TRIPACTIVITIES: (state, payload) => {
      state.tripActivities = payload;
      return state.tripActivities;
    },
  },
  getters: {
    tripActivities(state) {
      return state.tripActivities;
    },
  },
};
