<template>
  <v-app>
    <component :is="layout">
      <router-view />
    </component>
  </v-app>
</template>

<script>
import EmptyLayout from '@/layouts/EmptyLayout';
import MainLayout from '@/layouts/MainLayout';

export default {
  computed: {
    layout() {
      return (this.$route.meta.layout || 'empty') + '-layout';
    },
  },
  components: {
    EmptyLayout,
    MainLayout,
  },
};
</script>

<style lang="scss">
@import 'chartist/dist/scss/chartist.scss';
body,
#app {
  background: var(--v-accent-base);
}
#app * {
  font-family: Frutiger, sans-serif !important;
  letter-spacing: 0;
}
@font-face {
  font-family: Frutiger;
  src: url(./assets/fonts/FrutigerLTArabic-55Roman.ttf);
}
a {
  text-decoration: none;
}
.view-without-scroll {
  overflow: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
}
.view-without-scroll::-webkit-scrollbar {
  width: 0;
  background-color: transparent;
}
#app .clip {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.three-rows {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-clamp: 3;
  box-orient: vertical;
  max-height: 72px;
}
.f32 {
  font-weight: 900;
  font-size: 2rem;
}
.f18 {
  font-size: 1.125rem;
}
.f13 {
  font-size: 13px;
}
.f8 {
  font-size: 0.5rem;
}
.chartist-tooltip {
  opacity: 0;
  position: absolute;
  margin: 20px 0 0 10px;
  background: var(--v-primary-darken2);
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  text-align: center;
}
.chartist-tooltip.tooltip-show {
  opacity: 1;
}
.wrap {
  max-width: 1563px;
  width: 100%;
  margin: 0 auto;
}
.width100 {
  width: 100%;
}
.width50 {
  width: 50%;
}
.height100 {
  height: 100%;
}
.link {
  cursor: pointer !important;
}
.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
}
.gray-filter {
  filter: invert(76%) sepia(4%) saturate(23%) hue-rotate(341deg) brightness(97%) contrast(92%);
}
.black-filter {
  filter: brightness(0);
}
.primary-filter {
  filter: invert(34%) sepia(13%) saturate(3011%) hue-rotate(172deg) brightness(60%) contrast(95%);
}
.error-filter {
  filter: invert(34%) sepia(38%) saturate(4850%) hue-rotate(343deg) brightness(90%) contrast(84%);
}
.move-icon {
  cursor: move;
}
.item-content {
  // width: 100%;
  max-width: 830px;
}
.item-menu {
  min-height: 70vh;
  min-width: 260px;
  max-width: 260px;
  width: 100%;
  &_item {
    height: 48px;
    margin-top: 2px;
    display: flex;
    align-items: center;
  }
  &_item.gray::before {
    content: '';
    position: relative;
    left: -12px;
    display: inline-block;
    width: 8px;
    height: 38px;
    border-radius: 0px 6px 6px 0px;
    background: var(--v-secondary-base);
  }
}
.foto-card {
  border: 1px solid var(--v-gray-lighten1) !important;
  background-color: var(--v-gray-lighten4) !important;
}
input[type='file'] {
  cursor: pointer;
  display: block;
  height: 100%;
  left: 0;
  opacity: 0 !important;
  position: absolute;
  top: 0;
  width: 100%;
}
#app .v-input--switch__track {
  opacity: 1;
  color: var(--v-gray-lighten3);
}
#app .v-input--switch__thumb {
  color: white !important;
}
.border-blue {
  border: 1px solid var(--v-primary-base);
  background: #deeaf7;
}
.border-gray {
  border: 1px solid var(--v-gray-lighten2);
}
.dashad {
  border: 1px dashed var(--v-gray-lighten2) !important;
}
#app .radio {
  display: flex;
  align-items: center;
  min-height: 50px;
  border-radius: 10px;
  border: 1px solid var(--v-gray-lighten2);
  background: initial;
  .v-input--selection-controls__input {
    margin-top: 2px;
  }
}
#app .radio.v-input--is-label-active,
#app .radio.v-item--active {
  border: 1px solid var(--v-primary-base);
  background: #deeaf7;
}
#app .radio.error--text,
#app .error--text .radio {
  border: 1px solid var(--v-error-base) !important;
}
#app .radio.error--text {
  .v-input--selection-controls__ripple,
  .v-icon {
    color: var(--v-error-base) !important;
  }
}
#app .group-btn {
  .v-btn--outlined {
    border-color: var(--v-gray-lighten2) !important;
  }
  .v-item--active {
    border-color: var(--v-primary-base) !important;
    color: var(--v-primary-base) !important;
    background-color: var(--v-primary-lighten3) !important;
    border-left-width: thin !important;
  }
  .v-btn--active::before {
    opacity: 0 !important;
  }
}
.v-btn__content {
  font-size: 16px;
  font-weight: 400;
  text-transform: none;
  opacity: 1 !important;
}
.text-body-2 .v-btn__content {
  font-size: 14px;
}
.text-caption .v-btn__content {
  font-size: 13px;
}
.text-rtl,
.multi-direct .v-input:last-of-type {
  direction: rtl;
}
.v-btn {
  letter-spacing: initial !important;
  border-radius: 10px;
}
.close-btn {
  position: absolute;
  top: 4px;
  right: 4px;
}
.v-input {
  letter-spacing: initial !important;
  border-radius: 10px !important;
}
.label-table {
  max-width: 130px;
  width: 100%;
}
.data-table {
  max-width: 350px;
  min-width: 50px;
}
table tbody tr td {
  font-size: 16px !important;
  font-weight: 400 !important;
  min-height: 42px;
  height: 42px !important;
  color: var(--v-text-base) !important;
}
.v-time-picker-clock__item--active,
table tbody tr td:first-of-type {
  color: black !important;
}
table tbody tr:hover {
  background: var(--v-primary-lighten3) !important;
}
table thead tr th {
  background: var(--v-accent-base) !important;
  font-size: 16px !important;
  color: var(--v-text-base) !important;
  font-weight: 400 !important;
}
.v-data-table__empty-wrapper:hover {
  background: initial !important;
}
.v-data-table__empty-wrapper,
.v-data-table__empty-wrapper div:first-child {
  min-height: 350px;
}
.table-center-column span {
  text-align: center !important;
  display: inline-block;
  width: 100%;
}
.v-rating .v-icon {
  padding: 2px !important;
}
.field46 .v-input__append-inner,
.field46 .v-input__prepend-inner {
  margin-top: 10px !important;
}
.field46 .v-text-field__slot {
  margin-top: 2px !important;
}
@media screen and (max-width: 1263px) {
  .item-content {
    max-width: initial;
  }
}
@media screen and (max-width: 959px) {
  .item-menu {
    min-width: 200px;
  }
}
</style>
