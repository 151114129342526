import '@/plugins/axios';
import router from '@/router/index';

const api = '/platform/api/v1/accommodations/';

export default {
  state: {
    accommodationContentListShort: [{ plans: [] }],
    accommodationContentList: {},
    accommodationContent: { plans: [] },
  },
  actions: {
    getAccommodationShort(context, payload) {
      return axios.get(`${api}short/${payload ? `?src=${payload}` : ''}`).then((res) => {
        context.commit('GET_ACCOMMODATIONLISTSHORT', res.data);
      });
    },
    setAccommodationShort(context, payload) {
      context.commit('GET_ACCOMMODATIONLISTSHORT', [{ plans: [] }]);
    },
    getAccommodationContentList(context, { search, page }) {
      if (!!search || page > 1) {
        if (`${router.app._route.path}${!!search ? `?src=${search}&` : '?'}page=${page || 1}` != router.app._route.fullPath) {
          router.push(`${router.app._route.path}${!!search ? `?src=${search}&` : '?'}page=${page || 1}`);
        }
        return axios.get(`${api}${!!search ? `?src=${search}&` : '?'}page=${page - 1 || 0}`).then((res) => {
          context.commit('GET_ACCOMMODATIONCONTENTLIST', res.data);
        });
      } else {
        if (router.app._route.path != router.app._route.fullPath) {
          router.push(`${router.app._route.path}`);
        }
        return axios.get(`${api}?page=${page - 1 || 0}`).then((res) => {
          context.commit('GET_ACCOMMODATIONCONTENTLIST', res.data);
        });
      }
    },
    setAccommodationContentList: (context) => {
      context.commit('GET_ACCOMMODATIONCONTENTLIST', {});
    },
    createAccommodationContent(context, payload) {
      return axios({
        method: 'post',
        url: api,
        data: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      }).then((res) => {
        router.push(`/accommodations/${res.data.id}/general`);
      });
    },
    getAccommodationItem(context) {
      return axios.get(`${api}${router.app._route.params.accommodationId}`).then((res) => {
        for (let i = 0; i < res.data.plans.length; i++) {
          if (res.data.plans[i].id) {
            res.data.plans[i].priceN = res.data.plans[i].price / 100 || '';
          }
        }
        context.commit('GET_ACCOMMODATIONCONTENT', res.data);
      });
    },
    setAccommodationItem: (context, payload) => {
      context.commit('GET_ACCOMMODATIONCONTENT', { plans: [] });
    },
    updateAccommodationItem(context, payload) {
      return axios.put(`${api}${router.app._route.params.accommodationId}`, payload);
    },
    deleteAccommodation(context) {
      return axios.delete(`${api}${router.app._route.params.accommodationId}`).then(() => {
        router.push('/accommodations');
      });
    },
    setMediaAccommodation(context, payload) {
      return axios({
        method: 'put',
        url: `${api}${router.app._route.params.accommodationId}/picture`,
        data: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      }).then((res) => {
        setTimeout(() => {
          res.data.status == 'processing' ? context.dispatch('getAccommodationItem') : '';
        }, 500);
      });
    },
    deleteMediaAccommodation(context, payload) {
      return axios.delete(`${api}${router.app._route.params.accommodationId}/picture`);
    },
    createAccommodationItemPlan(context, payload) {
      return axios.post(`${api}${router.app._route.params.accommodationId}/plans/`, payload).then((res) => {
        for (let i = 0; i < res.data.plans.length; i++) {
          if (res.data.plans[i].id) {
            res.data.plans[i].priceN = res.data.plans[i].price / 100 || '';
          }
        }
        context.commit('GET_ACCOMMODATIONCONTENT', res.data);
      });
    },
    updateAccommodationItemPlan(context, { id, data }) {
      return axios.put(`${api}${router.app._route.params.accommodationId}/plans/${id}`, data);
    },
    deleteAccommodationItemPlan(context, payload) {
      return axios.delete(`${api}${router.app._route.params.accommodationId}/plans/${payload}`);
    },
  },
  mutations: {
    GET_ACCOMMODATIONLISTSHORT: (state, payload) => {
      state.accommodationContentListShort = payload;
      return state.accommodationContentListShort;
    },
    GET_ACCOMMODATIONCONTENTLIST: (state, payload) => {
      state.accommodationContentList = payload;
      return state.accommodationContentList;
    },
    GET_ACCOMMODATIONCONTENT: (state, payload) => {
      state.accommodationContent = payload;
      return state.accommodationContent;
    },
  },
  getters: {
    accommodationContentListShort(state) {
      return state.accommodationContentListShort;
    },
    accommodationContentList(state) {
      return state.accommodationContentList;
    },
    accommodationContent(state) {
      return state.accommodationContent;
    },
  },
};
