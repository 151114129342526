import '@/plugins/axios';
import router from '@/router/index';

const api = '/platform/api/v1/bookings/';

export default {
  state: {
    bookingList: {},
    booking: { customer: {}, trip: {}, personal_plan: null, general_plan: null, addons: [], passenger_info: [] },
    bookingAttachments: [],
  },
  actions: {
    getBookingList(context, { search, page, sort, status, customer }) {
      if (!!search || page > 1 || !!sort.length || status != 'all' || customer != 'all') {
        if (router.app._route.name != 'Home') {
          if (
            `${router.app._route.path}${customer != 'all' ? `?customer=${customer}&` : '?'}${status != 'all' ? `status=${status}&` : ''}${
              !!sort.length ? `sort=${sort}&` : ''
            }${!!search ? `src=${search}&` : ''}page=${page || 1}` != router.app._route.fullPath
          ) {
            router.push(
              `${router.app._route.path}${customer != 'all' ? `?customer=${customer}&` : '?'}${status != 'all' ? `status=${status}&` : ''}${
                !!sort.length ? `sort=${sort}&` : ''
              }${!!search ? `src=${search}&` : ''}page=${page || 1}`
            );
          }
        }
        const sortValue = [];
        for (var i = 0; i < sort.length; i++) {
          sortValue.push(`sort=${sort[i]}&`);
        }
        return axios
          .get(
            `${api}${customer != 'all' ? `?customer=${customer}&` : '?'}${status != 'all' ? `status=${status}&` : ''}${
              sortValue.join('') || ''
            }${!!search ? `src=${search}&` : ''}page=${page - 1 || 0}`
          )
          .then((res) => {
            context.commit('GET_BOOKINGLIST', res.data);
          });
      } else {
        if (router.app._route.name != 'Home') {
          if (router.app._route.path != router.app._route.fullPath) {
            router.push(`${router.app._route.path}`);
          }
        }
        return axios.get(`${api}`).then((res) => {
          context.commit('GET_BOOKINGLIST', res.data);
        });
      }
    },
    setBookingList: (context) => {
      context.commit('GET_BOOKINGLIST', {});
    },
    getBookingItem(context, payload) {
      return axios.get(`${api}${router.app._route.params.bookingId || payload}`).then((res) => {
        context.commit('GET_BOOKING', res.data);
      });
    },
    addBookingItemDestination(context, payload) {
      return axios.post(`${api}${router.app._route.params.bookingId}/surprise/destinations/`, payload).then((res) => {
        context.commit('GET_BOOKING', res.data);
      });
    },
    updateBookingItemDestination(context, { id, data }) {
      return axios.put(`${api}${router.app._route.params.bookingId}/surprise/destinations/${id}`, data).then((res) => {
        context.commit('GET_BOOKING', res.data);
      });
    },
    removeBookingItemDestination(context, payload) {
      return axios.delete(`${api}${router.app._route.params.bookingId}/surprise/destinations/${payload}`);
    },
    orderBookingItemDestination(context, { id, data }) {
      return axios.post(`${api}${router.app._route.params.bookingId}/surprise/destinations/${id}/order`, data).then((res) => {
        context.commit('GET_BOOKING', res.data);
      });
    },
    getBookingItemInvoice(context, payload) {
      return axios.get(`${api}${router.app._route.params.bookingId || payload}/invoice`, { responseType: 'arraybuffer' }).then((res) => {
        const blob = new Blob([res.data], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'invoice.pdf';
        link.click();
      });
    },
    setBookingItem: (context) => {
      context.commit('GET_BOOKING', { customer: {}, trip: {}, personal_plan: null, general_plan: null, addons: [], passenger_info: [] });
    },
    getBookingItemAttachments(context) {
      return axios.get(`${api}${router.app._route.params.bookingId}/attachments/`).then((res) => {
        context.commit('GET_BOOKINGATTACHMENTS', res.data);
      });
    },
    setBookingItemAttachments(context) {
      context.commit('GET_BOOKINGATTACHMENTS', []);
    },
    addBookingItemAttachments(context, payload) {
      return axios({
        method: 'post',
        url: `${api}${router.app._route.params.bookingId}/attachments/`,
        data: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    removeBookingItemAttachments(context, payload) {
      return axios.delete(`${api}${router.app._route.params.bookingId}/attachments/${payload}`);
    },
  },
  mutations: {
    GET_BOOKINGLIST: (state, payload) => {
      state.bookingList = payload;
      return state.bookingList;
    },
    GET_BOOKING: (state, payload) => {
      state.booking = payload;
      return state.booking;
    },
    GET_BOOKINGATTACHMENTS: (state, payload) => {
      state.bookingAttachments = payload;
      return state.bookingAttachments;
    },
  },
  getters: {
    bookingList(state) {
      return state.bookingList;
    },
    booking(state) {
      return state.booking;
    },
    bookingAttachments(state) {
      return state.bookingAttachments;
    },
  },
};
