import '@/plugins/axios';
import router from '@/router/index';

const api = '/platform/api/v1/mokafaa/';

export default {
  state: {
    mokafaaList: {},
    mokafaa: { customer: {} },
  },
  actions: {
    getMokafaaList(context, { search, page, sort, status, customer, from, to }) {
      if (!!search || page > 1 || !!sort.length || status != 'all' || customer != 'all' || !!from || !!to) {
        if (
          `${router.app._route.path}${customer != 'all' ? `?customer=${customer}&` : '?'}${status != 'all' ? `status=${status}&` : ''}${
            !!from ? `date_from=${from}&` : ''
          }${!!to ? `date_to=${to}&` : ''}${!!sort.length ? `sort=${sort}&` : ''}${!!search ? `src=${search}&` : ''}page=${page || 1}` !=
          router.app._route.fullPath
        ) {
          router.push(
            `${router.app._route.path}${customer != 'all' ? `?customer=${customer}&` : '?'}${status != 'all' ? `status=${status}&` : ''}${
              !!from ? `date_from=${from}&` : ''
            }${!!to ? `date_to=${to}&` : ''}${!!sort.length ? `sort=${sort}&` : ''}${!!search ? `src=${search}&` : ''}page=${page || 1}`
          );
        }
        const sortValue = [];
        for (var i = 0; i < sort.length; i++) {
          sortValue.push(`sort=${sort[i]}&`);
        }
        return axios
          .get(
            `${api}${customer != 'all' ? `?customer=${customer}&` : '?'}${status != 'all' ? `status=${status}&` : ''}${
              !!from ? `date_from=${from}&` : ''
            }${!!to ? `date_to=${to}&` : ''}${sortValue.join('') || ''}${!!search ? `src=${search}&` : ''}page=${page - 1 || 0}`
          )
          .then((res) => {
            context.commit('GET_MOKAFAALIST', res.data);
          });
      } else {
        if (router.app._route.path != router.app._route.fullPath) {
          router.push(`${router.app._route.path}`);
        }
        return axios.get(`${api}`).then((res) => {
          context.commit('GET_MOKAFAALIST', res.data);
        });
      }
    },
    setMokafaaList: (context) => {
      context.commit('GET_MOKAFAALIST', {});
    },
    getMokafaaItem(context) {
      return axios.get(`${api}${router.app._route.params.mokafaaId}`).then((res) => {
        context.commit('GET_MOKAFAA', res.data);
      });
    },
    setMokafaaItem: (context) => {
      context.commit('GET_MOKAFAA', { customer: {} });
    },
  },
  mutations: {
    GET_MOKAFAALIST: (state, payload) => {
      state.mokafaaList = payload;
      return state.mokafaaList;
    },
    GET_MOKAFAA: (state, payload) => {
      state.mokafaa = payload;
      return state.mokafaa;
    },
  },
  getters: {
    mokafaaList(state) {
      return state.mokafaaList;
    },
    mokafaa(state) {
      return state.mokafaa;
    },
  },
};
