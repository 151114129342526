import '@/plugins/axios';
import router from '@/router/index';

const api = '/platform/api/v1/experiences/';

export default {
  state: {
    expListShort: [],
    expList: {},
    exp: { media: [], destinations: [] },
    expScheduleList: [],
  },
  actions: {
    getExpShort(context, payload) {
      return axios.get(`${api}short/${payload ? `?src=${payload}` : ''}`).then((res) => {
        context.commit('GET_EXPLIST', res.data);
      });
    },
    getExpList(context, { expert, active, search, page }) {
      if (expert != 'all' || active != 'all' || !!search || page > 1) {
        if (
          `${router.app._route.path}${active != 'all' ? `?act=${active}&` : '?'}${expert != 'all' ? `exp=${expert}&` : ''}${
            !!search ? `src=${search}&` : ''
          }page=${page || 1}` != router.app._route.fullPath
        ) {
          router.push(
            `${router.app._route.path}${active != 'all' ? `?act=${active}&` : '?'}${expert != 'all' ? `exp=${expert}&` : ''}${
              !!search ? `src=${search}&` : ''
            }page=${page || 1}`
          );
        }
        return axios
          .get(
            `${api}${active != 'all' ? `?act=${active}&` : '?'}${expert != 'all' ? `exp=${expert}&` : ''}${
              !!search ? `src=${search}&` : ''
            }page=${page - 1 || 0}`
          )
          .then((res) => {
            context.commit('GET_EXPLIST', res.data);
          });
      } else {
        if (router.app._route.path != router.app._route.fullPath) {
          router.push(`${router.app._route.path}`);
        }
        return axios.get(`${api}?page=${page - 1 || 0}`).then((res) => {
          context.commit('GET_EXPLIST', res.data);
        });
      }
    },
    setExpList: (context) => {
      context.commit('GET_EXPLIST', {});
    },
    createExp(context, payload) {
      return axios.post(api, payload).then((res) => {
        router.push(`/experiences/${res.data.id}/general`);
      });
    },
    supportFunctionExp(context, payload) {
      const data = payload;
      for (let i = 0; i < data.media.length; i++) {
        setTimeout(() => {
          data.media[i].status == 'processing' ? context.dispatch('getExpItem') : '';
        }, 500);
      }
      if (data.components.some((i) => i.id != 1) || !data.components.length) {
        setTimeout(() => {
          data.components.push({ included: false, component: {} });
        }, 300);
      }
      setTimeout(() => {
        data.destinations.push({ country: {}, city: {} });
      }, 300);
      data.priceN = data.price / 100 || '';
      context.commit('GET_EXP', data);
    },
    getExpItem(context) {
      return axios.get(`${api}${router.app._route.params.expId}`).then((res) => {
        context.dispatch('supportFunctionExp', res.data);
      });
    },
    setExpItem: (context, payload) => {
      context.commit('GET_EXP', { media: [], destinations: [] });
    },
    updateExpItem(context, payload) {
      return axios.put(`${api}${router.app._route.params.expId}`, payload);
    },
    deleteExp(context) {
      return axios.delete(`${api}${router.app._route.params.expId}`).then(() => {
        router.push('/experiences');
      });
    },
    featuredExp(context, payload) {
      return axios.post(`${api}${router.app._route.params.expId}/featured`, payload).then((res) => {
        context.dispatch('supportFunctionExp', res.data);
      });
    },
    hiddenExp(context, payload) {
      return axios.post(`${api}${router.app._route.params.expId}/hidden`, payload).then((res) => {
        context.dispatch('supportFunctionExp', res.data);
      });
    },
    updateLoyaltyExp(context, payload) {
      return axios.post(`${api}${router.app._route.params.expId}/loyalty-programs`, payload).then((res) => {
        context.dispatch('supportFunctionExp', res.data);
      });
    },
    setMediaExp(context, payload) {
      return axios({
        method: 'post',
        url: `${api}${router.app._route.params.expId}/media/`,
        data: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      }).then((res) => {
        setTimeout(() => {
          res.data.status == 'processing' ? context.dispatch('getExpItem') : '';
        }, 500);
      });
    },
    deleteMediaExp(context, payload) {
      return axios.delete(`${api}${router.app._route.params.expId}/media/${payload}`);
    },
    updateActivitiesExp(context, payload) {
      return axios.post(`${api}${router.app._route.params.expId}/activities/`, payload).then((res) => {
        context.dispatch('supportFunctionExp', res.data);
      });
    },
    addComponentsExp(context, payload) {
      return axios.post(`${api}${router.app._route.params.expId}/components/`, payload).then((res) => {
        context.dispatch('supportFunctionExp', res.data);
      });
    },
    updateComponentsExp(context, { id, data }) {
      return axios.put(`${api}${router.app._route.params.expId}/components/${id}`, data).then((res) => {
        context.dispatch('supportFunctionExp', res.data);
      });
    },
    deleteComponentsExp(context, payload) {
      return axios.delete(`${api}${router.app._route.params.expId}/components/${payload}`);
    },
    addWhoExp(context, payload) {
      return axios.post(`${api}${router.app._route.params.expId}/who-can-join/`, payload).then((res) => {
        context.dispatch('supportFunctionExp', res.data);
      });
    },
    deleteWhoExp(context, payload) {
      return axios.delete(`${api}${router.app._route.params.expId}/who-can-join/${payload}`);
    },
    addKnowExp(context, payload) {
      return axios.post(`${api}${router.app._route.params.expId}/know-before-you-go/`, payload).then((res) => {
        context.dispatch('supportFunctionExp', res.data);
      });
    },
    deleteKnowExp(context, payload) {
      return axios.delete(`${api}${router.app._route.params.expId}/know-before-you-go/${payload}`);
    },
    addToDoExp(context, payload) {
      return axios.post(`${api}${router.app._route.params.expId}/not-to-do/`, payload).then((res) => {
        context.dispatch('supportFunctionExp', res.data);
      });
    },
    deleteToDoExp(context, payload) {
      return axios.delete(`${api}${router.app._route.params.expId}/not-to-do/${payload}`);
    },
    addFaqsExp(context, payload) {
      return axios.post(`${api}${router.app._route.params.expId}/faqs/`, payload).then((res) => {
        context.dispatch('supportFunctionExp', res.data);
      });
    },
    deleteFaqsExp(context, payload) {
      return axios.delete(`${api}${router.app._route.params.expId}/faqs/${payload}`);
    },
    getExpSchedule(context) {
      return axios.get(`${api}${router.app._route.params.expId}/schedule-days/`).then((res) => {
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].id) {
            res.data[i].events.push({ time: null });
            res.data[i].requirements.push({});
            setTimeout(() => {
              if (res.data[i].media.length) {
                res.data[i].media[0].status == 'processing' ? context.dispatch('getExpSchedule') : '';
              }
            }, 500);
          }
        }
        context.commit('GET_EXPSCHEDULELIST', res.data);
      });
    },
    setExpSchedule(context) {
      context.commit('GET_EXPSCHEDULELIST', []);
    },
    createExpScheduleItem(context, payload) {
      return axios({
        method: 'post',
        url: `${api}${router.app._route.params.expId}/schedule-days/`,
        data: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    updateExpScheduleItem(context, { id, data }) {
      return axios.put(`${api}${router.app._route.params.expId}/schedule-days/${id}`, data);
    },
    removeExpScheduleItem(context, payload) {
      return axios.delete(`${api}${router.app._route.params.expId}/schedule-days/${payload}`);
    },
    updateExpScheduleItemMedia(context, { id, data }) {
      return axios.post(`${api}${router.app._route.params.expId}/schedule-days/${id}/media`, data);
    },
    removeExpScheduleItemMedia(context, payload) {
      return axios.delete(`${api}${router.app._route.params.expId}/schedule-days/${payload}/media`).then(() => {
        context.dispatch('getExpSchedule');
      });
    },
    createExpScheduleItemEvent(context, { id, data }) {
      return axios.post(`${api}${router.app._route.params.expId}/schedule-days/${id}/events/`, data);
    },
    updateExpScheduleItemEvent(context, { id, data, event }) {
      return axios.put(`${api}${router.app._route.params.expId}/schedule-days/${id}/events/${event}`, data);
    },
    removeExpScheduleItemEvent(context, { id, event }) {
      return axios.delete(`${api}${router.app._route.params.expId}/schedule-days/${id}/events/${event}`);
    },
    createExpScheduleItemReq(context, { id, data }) {
      return axios.post(`${api}${router.app._route.params.expId}/schedule-days/${id}/requirements/`, data);
    },
    updateExpScheduleItemReq(context, { id, data, req }) {
      return axios.put(`${api}${router.app._route.params.expId}/schedule-days/${id}/requirements/${req}`, data);
    },
    removeExpScheduleItemReq(context, { id, req }) {
      return axios.delete(`${api}${router.app._route.params.expId}/schedule-days/${id}/requirements/${req}`);
    },
  },
  mutations: {
    GET_EXPLIST: (state, payload) => {
      state.expList = payload;
      return state.expList;
    },
    GET_EXP: (state, payload) => {
      state.exp = payload;
      return state.exp;
    },
    GET_EXPSCHEDULELIST: (state, payload) => {
      state.expScheduleList = payload;
      return state.expScheduleList;
    },
  },
  getters: {
    expList(state) {
      return state.expList;
    },
    exp(state) {
      return state.exp;
    },
    expScheduleList(state) {
      return state.expScheduleList;
    },
  },
};
