import '@/plugins/axios';
import router from '@/router/index';

const api = '/platform/api/v1/trip-discount-codes/';

export default {
  state: {
    discountCodesList: {},
    discountCodeItem: {},
  },
  actions: {
    getDiscountCodesList(context, { search, page, available }) {
      if (!!search || page > 1 || available != 'all') {
        if (
          `${router.app._route.path}${available != 'all' ? `?available=${available}&` : '?'}${!!search ? `src=${search}&` : ''}page=${
            page || 1
          }` != router.app._route.fullPath
        ) {
          router.push(
            `${router.app._route.path}${available != 'all' ? `?available=${available}&` : '?'}${!!search ? `src=${search}&` : ''}page=${
              page || 1
            }`
          );
        }
        return axios
          .get(`${api}${available != 'all' ? `?available=${available}&` : '?'}${!!search ? `src=${search}&` : ''}page=${page - 1 || 0}`)
          .then((res) => {
            context.commit('GET_DISCOUNTCODESLIST', res.data);
          });
      } else {
        if (router.app._route.path != router.app._route.fullPath) {
          router.push(`${router.app._route.path}`);
        }
        return axios.get(`${api}`).then((res) => {
          context.commit('GET_DISCOUNTCODESLIST', res.data);
        });
      }
    },
    setDiscountCodesList: (context) => {
      context.commit('GET_DISCOUNTCODESLIST', {});
    },
    createDiscountCode(context, payload) {
      return axios.post(api, payload).then((res) => {
        router.push(`/trip-discount-codes/${res.data.id}`);
      });
    },
    getDiscountCodeItem: (context) => {
      return axios.get(`${api}${router.app._route.params.discountId}`).then((res) => {
        res.data.discount_value = res.data.discount_value / 100;
        context.commit('GET_DISCOUNTCODEITEM', res.data);
      });
    },
    updateDiscountCodeItem: (context, payload) => {
      return axios.put(`${api}${router.app._route.params.discountId}`, payload).then((res) => {
        res.data.discount_value = res.data.discount_value / 100;
        context.commit('GET_DISCOUNTCODEITEM', res.data);
      });
    },
    setDiscountCodeItem: (context) => {
      context.commit('GET_DISCOUNTCODEITEM', {});
    },
  },
  mutations: {
    GET_DISCOUNTCODESLIST: (state, payload) => {
      state.discountCodesList = payload;
      return state.discountCodesList;
    },
    GET_DISCOUNTCODEITEM: (state, payload) => {
      state.discountCodeItem = payload;
      return state.discountCodeItem;
    },
  },
  getters: {
    discountCodesList(state) {
      return state.discountCodesList;
    },
    discountCodeItem(state) {
      return state.discountCodeItem;
    },
  },
};
