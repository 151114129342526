import '@/plugins/axios';
import router from '@/router/index';

const api = '/platform/api/v1/staff/';

export default {
  state: {
    staffList: {},
    staff: {},
  },
  actions: {
    getStaffList(context, { search, page }) {
      if (!!search || page > 1) {
        if (`${router.app._route.path}${!!search ? `?src=${search}&` : '?'}page=${page || 1}` != router.app._route.fullPath) {
          router.push(`${router.app._route.path}${!!search ? `?src=${search}&` : '?'}page=${page || 1}`);
        }
        return axios.get(`${api}${!!search ? `?src=${search}&` : '?'}page=${page - 1 || 0}`).then((res) => {
          context.commit('GET_STAFFLIST', res.data);
        });
      } else {
        if (router.app._route.path != router.app._route.fullPath) {
          router.push(`${router.app._route.path}`);
        }
        return axios.get(`${api}?page=${page - 1 || 0}`).then((res) => {
          context.commit('GET_STAFFLIST', res.data);
        });
      }
    },
    setStaffList: (context, payload) => {
      context.commit('GET_STAFFLIST', payload);
    },
    createStaff(context, payload) {
      return axios.post(api, payload).then((res) => {
        router.push(`/staff/${res.data.id}`);
      });
    },
    getStaffItem(context) {
      return axios.get(`${api}${router.app._route.params.staffId}`).then((res) => {
        context.commit('GET_STAFF', res.data);
      });
    },
    setStaffItem: (context, payload) => {
      context.commit('GET_STAFF', payload);
    },
    updateStaffItem(context, payload) {
      return axios.put(`${api}${router.app._route.params.staffId}`, payload).then((res) => {
        context.commit('GET_STAFF', res.data);
      });
    },
    setPhotoStaff(context, payload) {
      return axios({
        method: 'post',
        url: `${api}${router.app._route.params.staffId}/picture`,
        data: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      }).then((res) => {
        context.commit('GET_STAFF', res.data);
      });
    },
    deletePhotoStaff(context) {
      return axios.delete(`${api}${router.app._route.params.staffId}/picture`);
    },
    sendEmailStaff(context) {
      return axios.post(`${api}${router.app._route.params.staffId}/send-email-confirmation`).then((res) => {
        context.commit('GET_STAFF', res.data);
      });
    },
    resetPassStaff(context) {
      return axios.post(`${api}${router.app._route.params.staffId}/reset-password`).then(() => {
        context.dispatch('getStaff');
      });
    },
    activateStaff(context) {
      return axios.post(`${api}${router.app._route.params.staffId}/restore`).then((res) => {
        context.commit('GET_STAFF', res.data);
      });
    },
    deactivateStaff(context) {
      return axios.post(`${api}${router.app._route.params.staffId}/suspend`).then((res) => {
        context.commit('GET_STAFF', res.data);
      });
    },
  },
  mutations: {
    GET_STAFFLIST: (state, payload) => {
      state.staffList = payload;
      return state.staffList;
    },
    GET_STAFF: (state, payload) => {
      state.staff = payload;
      return state.staff;
    },
  },
  getters: {
    staffList(state) {
      return state.staffList;
    },
    staff(state) {
      return state.staff;
    },
  },
};
