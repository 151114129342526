import '@/plugins/axios';
import router from '@/router/index';

const api = '/platform/api/v1/customer-requests/';

export default {
  state: {
    callbackList: {},
    callback: { person: {} },
  },
  actions: {
    getCallbackList(context, { status, sort, search, page, type }) {
      if (status != 'all' || type != 'all' || !!sort.length || !!search || page > 1) {
        if (
          `${router.app._route.path}${type != 'all' ? `?type=${type}&` : '?'}${status != 'all' ? `status=${status}&` : ''}${
            !!search ? `src=${search}&` : ''
          }${!!sort.length ? `sort=${sort}&` : ''}page=${page || 1}` != router.app._route.fullPath
        ) {
          router.push(
            `${router.app._route.path}${type != 'all' ? `?type=${type}&` : '?'}${status != 'all' ? `status=${status}&` : ''}${
              !!search ? `src=${search}&` : ''
            }${!!sort.length ? `sort=${sort}&` : ''}page=${page || 1}`
          );
        }
        const sortValue = [];
        for (var i = 0; i < sort.length; i++) {
          sortValue.push(`sort=${sort[i]}&`);
        }
        return axios
          .get(
            `${api}${type != 'all' ? `?type=${type}&` : '?'}${status != 'all' ? `status=${status}&` : ''}${
              !!search ? `src=${search}&` : ''
            }${sortValue.join('') || ''}page=${page - 1 || 0}`
          )
          .then((res) => {
            context.commit('GET_CALLBACKLIST', res.data);
          });
      } else {
        if (router.app._route.path != router.app._route.fullPath) {
          router.push(`${router.app._route.path}`);
        }
        return axios.get(`${api}?page=${page - 1 || 0}`).then((res) => {
          context.commit('GET_CALLBACKLIST', res.data);
        });
      }
    },
    setCallbackList: (context) => {
      context.commit('GET_CALLBACKLIST', {});
    },
    getCallbackItem(context) {
      return axios.get(`${api}${router.app._route.params.callbackId}`).then((res) => {
        context.commit('GET_CALLBACK', res.data);
      });
    },
    setCallbackItem: (context) => {
      context.commit('GET_CALLBACK', { person: {} });
    },
    processedCallbackItem(context) {
      return axios.post(`${api}${router.app._route.params.callbackId}/processed`).then((res) => {
        context.commit('GET_CALLBACK', res.data);
      });
    },
    notesCallbackItem(context, payload) {
      return axios.post(`${api}${router.app._route.params.callbackId}/notes`, payload).then((res) => {
        context.commit('GET_CALLBACK', res.data);
      });
    },
  },
  mutations: {
    GET_CALLBACKLIST: (state, payload) => {
      state.callbackList = payload;
      return state.callbackList;
    },
    GET_CALLBACK: (state, payload) => {
      state.callback = payload;
      return state.callback;
    },
  },
  getters: {
    callbackList(state) {
      return state.callbackList;
    },
    callback(state) {
      return state.callback;
    },
  },
};
