import '@/plugins/axios';
import router from '@/router/index';

const api = '/platform/api/v1/product-orders/';

export default {
  state: {
    productOrdersList: {},
    productOrder: {},
  },
  actions: {
    getProductOrdersList(context, { vendor, search, page, status, sort }) {
      if (!!search || page > 1 || status != 'all' || sort != 'all') {
        if (vendor) {
          if (
            `${router.app._route.path}${status != 'all' ? `?status=${status}&` : '?'}${sort != 'all' ? `sort=${sort}&` : ''}${
              !!search ? `src=${search}&` : ''
            }page=${page || 1}` != router.app._route.fullPath
          ) {
            router.push(
              `${router.app._route.path}${status != 'all' ? `?status=${status}&` : '?'}${sort != 'all' ? `sort=${sort}&` : ''}${
                !!search ? `src=${search}&` : ''
              }page=${page || 1}`
            );
          }
        }
        return axios
          .get(
            `${api}${vendor || router.app._route.params.vendorId ? `?vendor=${vendor || router.app._route.params.vendorId}&` : '?'}${
              !!search ? `src=${search}&` : ''
            }${status != 'all' ? `status=${status}&` : ''}${sort != 'all' ? `sort=${sort}&` : ''}page=${page - 1 || 0}`
          )
          .then((res) => {
            context.commit('GET_PRODUCTORDERSLIST', res.data);
          });
      } else {
        if (vendor) {
          if (router.app._route.path != router.app._route.fullPath) {
            router.push(`${router.app._route.path}`);
          }
        }
        return axios
          .get(
            `${api}${vendor || router.app._route.params.vendorId ? `?vendor=${vendor || router.app._route.params.vendorId}&` : '?'}&page=${
              page - 1 || 0
            }`
          )
          .then((res) => {
            context.commit('GET_PRODUCTORDERSLIST', res.data);
          });
      }
    },
    setProductOrdersList: (context) => {
      context.commit('GET_PRODUCTORDERSLIST', {});
    },
    getProductOrderItem(context, payload) {
      return axios.get(`${api}${payload}`).then((res) => {
        context.commit('GET_PRODUCTORDER', res.data);
      });
    },
    setProductOrderItem: (context, payload) => {
      context.commit('GET_PRODUCTORDER', payload);
    },
    statusProductOrderItem(context, { id, data }) {
      return axios.post(`${api}${id}/status`, data).then((res) => {
        context.commit('GET_PRODUCTORDER', res.data);
      });
    },
  },
  mutations: {
    GET_PRODUCTORDERSLIST: (state, payload) => {
      state.productOrdersList = payload;
      return state.productOrdersList;
    },
    GET_PRODUCTORDER: (state, payload) => {
      state.productOrder = payload;
      return state.productOrder;
    },
  },
  getters: {
    productOrdersList(state) {
      return state.productOrdersList;
    },
    productOrder(state) {
      return state.productOrder;
    },
  },
};
