<template>
  <div>
    <v-navigation-drawer color="white" fixed overlay-opacity temporary v-model="drawer" width="265" class="mobile-menu">
      <!-- <div class="logo">
        <v-img max-height="35" max-width="99" contain src="@/assets/img/logo.svg" alt="logo"></v-img>
      </div> -->
      <MenuList />
    </v-navigation-drawer>
    <v-app-bar elevation="0" height="60" color="primary" class="header">
      <div class="wrap d-flex flex-wrap align-center px-md-5">
        <v-app-bar-nav-icon @click="drawer = !drawer" class="d-lg-none d-block">
          <v-icon color="white" v-if="!drawer">mdi-menu</v-icon>
          <v-icon color="white" v-else>mdi-close</v-icon>
        </v-app-bar-nav-icon>
        <v-spacer class="d-lg-none d-block"></v-spacer>
        <router-link to="/"><v-img max-height="35" max-width="99" contain src="@/assets/img/logo.svg" alt="logo"></v-img></router-link>
        <v-spacer></v-spacer>
        <!-- <v-img class="link" height="20" max-width="20" contain src="@/assets/icon/bell.svg"></v-img> -->
        <div class="text-center ml-5">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-list color="transparent" class="pa-0">
                  <v-list-item class="px-0">
                    <v-list-item-avatar color="gray lighten-2" class="ma-0">
                      <v-img v-if="data.picture != null" :src="data.picture"></v-img>
                      <div v-else class="d-flex align-center">
                        <v-img class="gray-filter" contain width="24" height="24" src="@/assets/icon/user.svg"></v-img>
                      </div>
                    </v-list-item-avatar>
                    <v-list-item-content class="white--text ms-3 text-start">
                      <div class="font-weight-bold">{{ data.first_name }} {{ data.last_name }}</div>
                      <div class="f13 text-capitalize">{{ data.role }}</div>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </div>
            </template>
            <v-list>
              <v-list-item link to="/profile">
                <v-list-item-icon>
                  <div class="d-flex align-center">
                    <v-img class="black-filter" contain width="20" height="20" src="@/assets/icon/user.svg"></v-img>
                  </div>
                </v-list-item-icon>
                <v-list-item-title>Profile</v-list-item-title>
              </v-list-item>
              <v-list-item link @click="logout">
                <v-list-item-icon>
                  <div class="d-flex align-center">
                    <v-img class="logout-icon" contain width="20" height="20" src="@/assets/icon/download.svg"></v-img>
                  </div>
                </v-list-item-icon>
                <v-list-item-title>Sing out</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import MenuList from './MenuList.vue';

export default {
  components: {
    MenuList,
  },
  data() {
    return {
      drawer: false,
    };
  },
  mounted() {
    this.$store.dispatch('getProfile');
  },
  methods: {
    async logout() {
      localStorage.removeItem('jwt_token');
      this.$router.push('/login');
    },
  },
  computed: {
    data() {
      return this.$store.getters.profile;
    },
  },
};
</script>

<style>
/* .header .v-toolbar__content {
  width: calc(100% - 265px);
  margin-left: auto;
} */
.header .wrap {
  max-width: 1440px;
}
.mobile-menu {
  top: 60px !important;
  height: calc(100% - 60px) !important;
}
.logout-icon {
  filter: brightness(0);
  transform: rotate(270deg);
}
@media screen and (max-width: 1263px) {
  .header .v-toolbar__content {
    width: initial;
    margin-left: initial;
  }
}
</style>
