import '@/plugins/axios';
import router from '@/router/index';

const api = '/platform/api/v1/settings/';

export default {
  state: {
    settingsTripTypes: {},
    settingsTripRequest: {},
    settingsTrip: {},
    settingsSurpriseTrip: {},
    settingsBookings: {},
    settingsTabby: {},
    settingsMobile: { ios: {}, android: {}, explore_sections: [] },
    settingsWeb: { home_sections: [], home_banners: [] },
    settingsSupport: {},
    settingsBilling: {},
    settingsWallet: {},
    settingsVat: [],
    settingsServiceFee: [],
  },
  actions: {
    getSettingsTripTypes(context) {
      return axios.get(`${api}trip-types`).then((res) => {
        context.commit('GET_SETTINGSTRIPTYPES', res.data);
      });
    },
    saveSettingsTripTypes(context, payload) {
      return axios.put(`${api}trip-types`, payload).then((res) => {
        context.commit('GET_SETTINGSTRIPTYPES', res.data);
      });
    },
    getSettingsTripRequest(context) {
      return axios.get(`${api}trip-request`).then((res) => {
        context.commit('GET_SETTINGSTRIPREQUEST', res.data);
      });
    },
    updateSettingsTripRequest(context, payload) {
      return axios.put(`${api}trip-request`, payload).then((res) => {
        context.commit('GET_SETTINGSTRIPREQUEST', res.data);
      });
    },
    getSettingsTrip(context) {
      return axios.get(`${api}trip`).then((res) => {
        context.commit('GET_SETTINGSTRIP', res.data);
      });
    },
    updateSettingsTrip(context, payload) {
      return axios.put(`${api}trip`, payload).then((res) => {
        context.commit('GET_SETTINGSTRIP', res.data);
      });
    },
    getSettingsSurpriseTrip(context) {
      return axios.get(`${api}surprise-trip`).then((res) => {
        context.commit('GET_SETTINGSSURPRISETRIP', res.data);
      });
    },
    updateSettingsSurpriseTrip(context, payload) {
      return axios.put(`${api}surprise-trip`, payload).then((res) => {
        context.commit('GET_SETTINGSSURPRISETRIP', res.data);
      });
    },
    getSettingsBooking(context) {
      return axios.get(`${api}booking`).then((res) => {
        context.commit('GET_SETTINGSBOOKINGS', res.data);
      });
    },
    updateSettingsBooking(context, payload) {
      return axios.put(`${api}booking`, payload).then((res) => {
        context.commit('GET_SETTINGSBOOKINGS', res.data);
      });
    },
    getSettingsTabby(context) {
      return axios.get(`${api}tabby`).then((res) => {
        res.data.max_amountN = res.data.max_amount / 100;
        context.commit('GET_SETTINGSTABBY', res.data);
      });
    },
    updateSettingsTabby(context, payload) {
      return axios.put(`${api}tabby`, payload).then((res) => {
        res.data.max_amountN = res.data.max_amount / 100;
        context.commit('GET_SETTINGSTABBY', res.data);
      });
    },
    getSettingsMobile(context) {
      return axios.get(`${api}mobile-client`).then((res) => {
        context.commit('GET_SETTINGSMOBILE', res.data);
      });
    },
    updateSettingsMobile(context, payload) {
      return axios.put(`${api}mobile-client`, payload).then((res) => {
        context.commit('GET_SETTINGSMOBILE', res.data);
      });
    },
    createSettingsMobileExplore(context, payload) {
      return axios.post(`${api}mobile-client/explore-sections/`, payload).then((res) => {
        context.commit('GET_SETTINGSMOBILE', res.data);
      });
    },
    updateSettingsMobileExplore(context, { id, data }) {
      return axios.put(`${api}mobile-client/explore-sections/${id}`, data).then((res) => {
        context.commit('GET_SETTINGSMOBILE', res.data);
      });
    },
    deleteSettingsMobileExplore(context, payload) {
      return axios.delete(`${api}mobile-client/explore-sections/${payload}`).then((res) => {
        context.commit('GET_SETTINGSMOBILE', res.data);
      });
    },
    updateSettingsMobileExploreOrder(context, { id, data }) {
      return axios.put(`${api}mobile-client/explore-sections/${id}/order`, data).then((res) => {
        context.commit('GET_SETTINGSMOBILE', res.data);
      });
    },
    getSettingsSupport(context) {
      return axios.get(`${api}support`).then((res) => {
        context.commit('GET_SETTINGSSUPPORT', res.data);
      });
    },
    updateSettingsSupport(context, payload) {
      return axios.put(`${api}support`, payload).then((res) => {
        context.commit('GET_SETTINGSSUPPORT', res.data);
      });
    },
    //                               WEB
    getSettingsWeb(context) {
      return axios.get(`${api}web-client`).then((res) => {
        context.commit('GET_SETTINGSWEB', res.data);
      });
    },
    addSettingsWebHome(context, payload) {
      return axios.post(`${api}web-client/home-sections/`, payload).then((res) => {
        context.commit('GET_SETTINGSWEB', res.data);
      });
    },
    updateSettingsWebHome(context, { id, data }) {
      return axios.put(`${api}web-client/home-sections/${id}`, data).then((res) => {
        context.commit('GET_SETTINGSWEB', res.data);
      });
    },
    removeSettingsWebHome(context, payload) {
      return axios.delete(`${api}web-client/home-sections/${payload}`);
    },
    updateSettingsWebHomeOrder(context, { id, data }) {
      return axios.post(`${api}web-client/home-sections/${id}/order`, data).then((res) => {
        context.commit('GET_SETTINGSWEB', res.data);
      });
    },
    addSettingsWebBanner(context, payload) {
      return axios({
        method: 'post',
        url: `${api}web-client/home-banner/`,
        data: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      }).then((res) => {
        context.commit('GET_SETTINGSWEB', res.data);
      });
    },
    updateSettingsWebBanner(context, { id, data }) {
      return axios.put(`${api}web-client/home-banner/${id}`, data).then((res) => {
        context.commit('GET_SETTINGSWEB', res.data);
      });
    },
    removeSettingsWebBanner(context, payload) {
      return axios.delete(`${api}web-client/home-banner/${payload}`);
    },
    updateSettingsWebBannerOrder(context, { id, data }) {
      return axios.post(`${api}web-client/home-banner/${id}/order`, data).then((res) => {
        context.commit('GET_SETTINGSWEB', res.data);
      });
    },
    addSettingsWebAdBanner(context, payload) {
      return axios({
        method: 'post',
        url: `${api}web-client/ad-banner/`,
        data: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      }).then((res) => {
        context.commit('GET_SETTINGSWEB', res.data);
      });
    },
    updateSettingsWebAdBanner(context, payload) {
      return axios({
        method: 'patch',
        url: `${api}web-client/ad-banner/`,
        data: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      }).then((res) => {
        context.commit('GET_SETTINGSWEB', res.data);
      });
    },
    removeSettingsWebAdBanner(context) {
      return axios.delete(`${api}web-client/ad-banner/`).then((res) => {
        context.commit('GET_SETTINGSWEB', res.data);
      });
    },
    addSettingsWebAppBarLink(context, payload) {
      return axios.post(`${api}web-client/home-app-bar-links/`, payload).then((res) => {
        context.commit('GET_SETTINGSWEB', res.data);
      });
    },
    updateSettingsWebAppBarLink(context, { id, data }) {
      return axios.put(`${api}web-client/home-app-bar-links/${id}`, data).then((res) => {
        context.commit('GET_SETTINGSWEB', res.data);
      });
    },
    removeSettingsWebAppBarLink(context, payload) {
      return axios.delete(`${api}web-client/home-app-bar-links/${payload}`);
    },
    updateSettingsWebAppBarLinkOrder(context, { id, data }) {
      return axios.post(`${api}web-client/home-app-bar-links/${id}/order`, data).then((res) => {
        context.commit('GET_SETTINGSWEB', res.data);
      });
    },
    //                               Billing
    getSettingsBilling(context) {
      return axios.get(`${api}billing`).then((res) => {
        context.commit('GET_SETTINGSBILLING', res.data);
      });
    },
    updateSettingsBilling(context, payload) {
      return axios.put(`${api}billing`, payload).then((res) => {
        context.commit('GET_SETTINGSBILLING', res.data);
      });
    },
    //                               Wallet
    getSettingsWallet(context) {
      return axios.get(`${api}digital-wallet`).then((res) => {
        context.commit('GET_SETTINGSWALLET', res.data);
      });
    },
    updateSettingsWallet(context, { id, data }) {
      return axios.put(`${api}digital-wallet/trip-types-reward${id}`, data).then((res) => {
        context.commit('GET_SETTINGSWALLET', res.data);
      });
    },
    //                               VAT
    getSettingsVat(context, payload) {
      return axios.get(`${api}vat-options/${payload ? payload : ''}`).then((res) => {
        for (let i = 0; i < res.data.length; i++) {
          res.data[i].value = res.data[i].value / 100 || 0;
        }
        context.commit('GET_SETTINGSVAT', res.data);
      });
    },
    addSettingsVat(context, payload) {
      return axios.post(`${api}vat-options/`, payload);
    },
    updateSettingsVat(context, { id, data }) {
      return axios.put(`${api}vat-options/${id}`, data);
    },
    activeSettingsVat(context, { id, data }) {
      return axios.post(`${api}vat-options/${id}/active`, data);
    },
    defaultSettingsVat(context, payload) {
      return axios.post(`${api}vat-options/${payload}/default`).then(() => {
        context.dispatch('getSettingsVat');
      });
    },
    //                               Service fee
    getSettingsServiceFee(context, payload) {
      return axios.get(`${api}service-fee-options/${payload ? payload : ''}`).then((res) => {
        for (let i = 0; i < res.data.length; i++) {
          res.data[i].value = res.data[i].value / 100 || 0;
        }
        context.commit('GET_SETTINGSSERVICEFEE', res.data);
      });
    },
    addSettingsServiceFee(context, payload) {
      return axios.post(`${api}service-fee-options/`, payload);
    },
    updateSettingsServiceFee(context, { id, data }) {
      return axios.put(`${api}service-fee-options/${id}`, data);
    },
    activeSettingsServiceFee(context, { id, data }) {
      return axios.post(`${api}service-fee-options/${id}/active`, data);
    },
    defaultSettingsServiceFee(context, payload) {
      return axios.post(`${api}service-fee-options/${payload}/default`).then(() => {
        context.dispatch('getSettingsServiceFee');
      });
    },
    clearSettings: (context) => {
      context.commit('GET_SETTINGSTRIPTYPES', {});
      context.commit('GET_SETTINGSTRIPREQUEST', {});
      context.commit('GET_SETTINGSTRIP', {});
      context.commit('GET_SETTINGSSURPRISETRIP', {});
      context.commit('GET_SETTINGSBOOKINGS', {});
      context.commit('GET_SETTINGSTABBY', {});
      context.commit('GET_SETTINGSMOBILE', { ios: {}, android: {}, explore_sections: [] });
      context.commit('GET_SETTINGSSUPPORT', {});
      context.commit('GET_SETTINGSWEB', { home_sections: [], home_banners: [] });
      context.commit('GET_SETTINGSBILLING', {});
      context.commit('GET_SETTINGSWALLET', {});
      context.commit('GET_SETTINGSVAT', []);
      context.commit('GET_SETTINGSSERVICEFEE', []);
    },
  },
  mutations: {
    GET_SETTINGSTRIPTYPES: (state, payload) => {
      state.settingsTripTypes = payload;
      return state.settingsTripTypes;
    },
    GET_SETTINGSTRIPREQUEST: (state, payload) => {
      state.settingsTripRequest = payload;
      return state.settingsTripRequest;
    },
    GET_SETTINGSTRIP: (state, payload) => {
      state.settingsTrip = payload;
      return state.settingsTrip;
    },
    GET_SETTINGSSURPRISETRIP: (state, payload) => {
      state.settingsSurpriseTrip = payload;
      return state.settingsSurpriseTrip;
    },
    GET_SETTINGSBOOKINGS: (state, payload) => {
      state.settingsBookings = payload;
      return state.settingsBookings;
    },
    GET_SETTINGSTABBY: (state, payload) => {
      state.settingsTabby = payload;
      return state.settingsTabby;
    },
    GET_SETTINGSMOBILE: (state, payload) => {
      state.settingsMobile = payload;
      return state.settingsMobile;
    },
    GET_SETTINGSWEB: (state, payload) => {
      state.settingsWeb = payload;
      return state.settingsWeb;
    },
    GET_SETTINGSSUPPORT: (state, payload) => {
      state.settingsSupport = payload;
      return state.settingsSupport;
    },
    GET_SETTINGSBILLING: (state, payload) => {
      state.settingsBilling = payload;
      return state.settingsBilling;
    },
    GET_SETTINGSWALLET: (state, payload) => {
      state.settingsWallet = payload;
      return state.settingsWallet;
    },
    GET_SETTINGSVAT: (state, payload) => {
      state.settingsVat = payload;
      return state.settingsVat;
    },
    GET_SETTINGSSERVICEFEE: (state, payload) => {
      state.settingsServiceFee = payload;
      return state.settingsServiceFee;
    },
  },
  getters: {
    settingsTripTypes(state) {
      return state.settingsTripTypes;
    },
    settingsTripRequest(state) {
      return state.settingsTripRequest;
    },
    settingsTrip(state) {
      return state.settingsTrip;
    },
    settingsSurpriseTrip(state) {
      return state.settingsSurpriseTrip;
    },
    settingsBookings(state) {
      return state.settingsBookings;
    },
    settingsTabby(state) {
      return state.settingsTabby;
    },
    settingsMobile(state) {
      return state.settingsMobile;
    },
    settingsWeb(state) {
      return state.settingsWeb;
    },
    settingsSupport(state) {
      return state.settingsSupport;
    },
    settingsBilling(state) {
      return state.settingsBilling;
    },
    settingsWallet(state) {
      return state.settingsWallet;
    },
    settingsVat(state) {
      return state.settingsVat;
    },
    settingsServiceFee(state) {
      return state.settingsServiceFee;
    },
  },
};
