import '@/plugins/axios';
import router from '@/router/index';

const api = '/platform/api/v1/vendor-products/';

export default {
  state: {
    vendorProductsShort: [],
    vendorProductsList: {},
    vendorProduct: {},
  },
  actions: {
    getVendorProductsShort(context, { search, vendor }) {
      return axios.get(`${api}${search ? `?src=${search}` : ''}${vendor ? `${search ? '&' : '?'}vendor=${vendor}` : ''}`).then((res) => {
        context.commit('GET_VENDORPRODUCTSSHORT', res.data);
      });
    },
    getVendorProductsList(context, { vendor, search, page, status }) {
      if (!!search || page > 1 || status != 'all') {
        if (vendor) {
          if (
            `${router.app._route.path}${!!search ? `?src=${search}&` : '?'}${status != 'all' ? `status=${status}&` : ''}page=${
              page || 1
            }` != router.app._route.fullPath
          ) {
            router.push(
              `${router.app._route.path}${!!search ? `?src=${search}&` : '?'}${status != 'all' ? `status=${status}&` : ''}page=${page || 1}`
            );
          }
        }
        return axios
          .get(
            `${api}?vendor=${vendor ? vendor : router.app._route.params.vendorId}&${!!search ? `src=${search}&` : ''}${
              status != 'all' ? `status=${status}&` : ''
            }page=${page - 1 || 0}`
          )
          .then((res) => {
            context.commit('GET_VENDORPRODUCTSLIST', res.data);
          });
      } else {
        if (vendor) {
          if (router.app._route.path != router.app._route.fullPath) {
            router.push(`${router.app._route.path}`);
          }
        }
        return axios.get(`${api}?vendor=${vendor ? vendor : router.app._route.params.vendorId}&page=${page - 1 || 0}`).then((res) => {
          context.commit('GET_VENDORPRODUCTSLIST', res.data);
        });
      }
    },
    setVendorProductsList: (context) => {
      context.commit('GET_VENDORPRODUCTSSHORT', []);
      context.commit('GET_VENDORPRODUCTSLIST', {});
    },
    createVendorProduct(context, payload) {
      return axios.post(`${api}`, payload);
    },
    getVendorProductItem(context, payload) {
      return axios.get(`${api}${payload}`).then((res) => {
        for (let i = 0; i < res.data.media.length; i++) {
          setTimeout(() => {
            res.data.media[i].status == 'processing' ? context.dispatch('getVendorProductItem', res.data.id) : '';
          }, 1000);
        }
        res.data.priceR = res.data.price / 100;
        context.commit('GET_VENDORPRODUCT', res.data);
      });
    },
    setVendorProductItem: (context, payload) => {
      context.commit('GET_VENDORPRODUCT', payload);
    },
    updateVendorProductItem(context, { id, data }) {
      return axios.put(`${api}${id}`, data).then((res) => {
        res.data.priceR = res.data.price / 100;
        context.commit('GET_VENDORPRODUCT', res.data);
      });
    },
    deleteVendorProductItem(context, payload) {
      return axios.delete(`${api}${payload}`);
    },
    statusVendorProductItem(context, { id, data }) {
      return axios.post(`${api}${id}/status`, data).then((res) => {
        res.data.priceR = res.data.price / 100;
        context.commit('GET_VENDORPRODUCT', res.data);
      });
    },
    setMediaVendorProduct(context, { id, data }) {
      return axios({
        method: 'post',
        url: `${api}${id}/media/`,
        data: data,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      }).then((res) => {
        context.dispatch('getVendorProductItem', res.data.id);
      });
    },
    deleteMediaVendorProduct(context, { id, media }) {
      return axios.delete(`${api}${id}/media/${media}`);
    },
  },
  mutations: {
    GET_VENDORPRODUCTSSHORT: (state, payload) => {
      state.vendorProductsShort = payload;
      return state.vendorProductsShort;
    },
    GET_VENDORPRODUCTSLIST: (state, payload) => {
      state.vendorProductsList = payload;
      return state.vendorProductsList;
    },
    GET_VENDORPRODUCT: (state, payload) => {
      state.vendorProduct = payload;
      return state.vendorProduct;
    },
  },
  getters: {
    vendorProductsShort(state) {
      return state.vendorProductsShort;
    },
    vendorProductsList(state) {
      return state.vendorProductsList;
    },
    vendorProduct(state) {
      return state.vendorProduct;
    },
  },
};
