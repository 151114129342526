import '@/plugins/axios';
import router from '@/router/index';

const api = '/platform/api/v1/dw-transactions/';

export default {
  state: {
    walletList: {},
    wallet: { customer: {} },
  },
  actions: {
    getWalletList(context, { search, page, sort, type, customer, from, to }) {
      if (!!search || page > 1 || !!sort.length || type != 'all' || customer != 'all' || !!from || !!to) {
        if (
          `${router.app._route.path}${customer != 'all' ? `?customer=${customer}&` : '?'}${type != 'all' ? `type=${type}&` : ''}${
            !!from ? `date_from=${from}&` : ''
          }${!!to ? `date_to=${to}&` : ''}${!!sort.length ? `sort=${sort}&` : ''}${!!search ? `src=${search}&` : ''}page=${page || 1}` !=
          router.app._route.fullPath
        ) {
          router.push(
            `${router.app._route.path}${customer != 'all' ? `?customer=${customer}&` : '?'}${type != 'all' ? `type=${type}&` : ''}${
              !!from ? `date_from=${from}&` : ''
            }${!!to ? `date_to=${to}&` : ''}${!!sort.length ? `sort=${sort}&` : ''}${!!search ? `src=${search}&` : ''}page=${page || 1}`
          );
        }
        const sortValue = [];
        for (var i = 0; i < sort.length; i++) {
          sortValue.push(`sort=${sort[i]}&`);
        }
        return axios
          .get(
            `${api}${customer != 'all' ? `?customer=${customer}&` : '?'}${type != 'all' ? `type=${type}&` : ''}${
              !!from ? `date_from=${from}&` : ''
            }${!!to ? `date_to=${to}&` : ''}${sortValue.join('') || ''}${!!search ? `src=${search}&` : ''}page=${page - 1 || 0}`
          )
          .then((res) => {
            context.commit('GET_WALLETLIST', res.data);
          });
      } else {
        if (router.app._route.path != router.app._route.fullPath) {
          router.push(`${router.app._route.path}`);
        }
        return axios.get(`${api}`).then((res) => {
          context.commit('GET_WALLETLIST', res.data);
        });
      }
    },
    setWalletList: (context) => {
      context.commit('GET_WALLETLIST', {});
    },
    getWalletItem(context) {
      return axios.get(`${api}${router.app._route.params.walletId}`).then((res) => {
        context.commit('GET_WALLET', res.data);
      });
    },
    setWalletItem: (context) => {
      context.commit('GET_WALLET', { customer: {} });
    },
  },
  mutations: {
    GET_WALLETLIST: (state, payload) => {
      state.walletList = payload;
      return state.walletList;
    },
    GET_WALLET: (state, payload) => {
      state.wallet = payload;
      return state.wallet;
    },
  },
  getters: {
    walletList(state) {
      return state.walletList;
    },
    wallet(state) {
      return state.wallet;
    },
  },
};
