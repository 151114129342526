import '@/plugins/axios';
import router from '@/router/index';

const api = '/platform/api/v1/reward-campaigns/';
const apiG = '/platform/api/v1/reward-campaign-code-groups/';

export default {
  state: {
    promotionsList: {},
    promotionItem: {},
    promotionCodeList: [],
    promotionCode: {},
    promotionActivations: {},
    promotionGroupList: [],
    promotionGroup: {},
    cardBinsList: [],
  },
  actions: {
    getPromotionsList(context, { search, page, available }) {
      if (!!search || page > 1 || available != 'all') {
        if (
          `${router.app._route.path}${available != 'all' ? `?available=${available}&` : '?'}${!!search ? `src=${search}&` : ''}page=${
            page || 1
          }` != router.app._route.fullPath
        ) {
          router.push(
            `${router.app._route.path}${available != 'all' ? `?available=${available}&` : '?'}${!!search ? `src=${search}&` : ''}page=${
              page || 1
            }`
          );
        }
        return axios
          .get(`${api}${available != 'all' ? `?available=${available}&` : '?'}${!!search ? `src=${search}&` : ''}page=${page - 1 || 0}`)
          .then((res) => {
            context.commit('GET_PROMOTIONSLIST', res.data);
          });
      } else {
        if (router.app._route.path != router.app._route.fullPath) {
          router.push(`${router.app._route.path}`);
        }
        return axios.get(`${api}?page=${page - 1 || 0}`).then((res) => {
          context.commit('GET_PROMOTIONSLIST', res.data);
        });
      }
    },
    setPromotionsList: (context) => {
      context.commit('GET_PROMOTIONSLIST', {});
    },
    createPromotion(context, payload) {
      return axios({
        method: 'post',
        url: `${api}`,
        data: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      }).then((res) => {
        router.push(`/promotions/${res.data.id}/campaign-details`);
      });
    },
    getPromotionItem: (context) => {
      return axios.get(`${api}${router.app._route.params.promotionId}`).then((res) => {
        res.data.reward_before_purchase = res.data.reward_before_purchase / 100;
        res.data.reward_after_purchase = res.data.reward_after_purchase / 100;
        res.data.reward_after_purchase_max = res.data.reward_after_purchase_max / 100;
        context.commit('GET_PROMOTIONITEM', res.data);
      });
    },
    updatePromotionItem: (context, payload) => {
      return axios.put(`${api}${router.app._route.params.promotionId}`, payload).then((res) => {
        res.data.reward_before_purchase = res.data.reward_before_purchase / 100;
        res.data.reward_after_purchase = res.data.reward_after_purchase / 100;
        res.data.reward_after_purchase_max = res.data.reward_after_purchase_max / 100;
        context.commit('GET_PROMOTIONITEM', res.data);
      });
    },
    removePromotionItem: (context) => {
      return axios.delete(`${api}${router.app._route.params.promotionId}`).then(() => {
        router.push('/promotions');
      });
    },
    updatePromotionItemImg(context, payload) {
      return axios({
        method: 'put',
        url: `${api}${router.app._route.params.promotionId}/picture`,
        data: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      }).then((res) => {
        res.data.reward_before_purchase = res.data.reward_before_purchase / 100;
        res.data.reward_after_purchase = res.data.reward_after_purchase / 100;
        res.data.reward_after_purchase_max = res.data.reward_after_purchase_max / 100;
        context.commit('GET_PROMOTIONITEM', res.data);
      });
    },
    removePromotionItemImg: (context) => {
      return axios.delete(`${api}${router.app._route.params.promotionId}/picture`);
    },
    setPromotionItem: (context) => {
      context.commit('GET_PROMOTIONITEM', {});
    },
    //                               CODES
    getPromotionCodeList: (context) => {
      return axios.get(`${api}${router.app._route.params.promotionId}/codes/`).then((res) => {
        context.commit('GET_PROMOTIONCODELIST', res.data);
      });
    },
    setPromotionCodeList: (context) => {
      context.commit('GET_PROMOTIONCODELIST', []);
    },
    createPromotionCode: (context, payload) => {
      return axios.post(`${api}${router.app._route.params.promotionId}/codes/`, payload).then(() => {
        context.dispatch('getPromotionCodeList');
      });
    },
    getPromotionCode: (context, payload) => {
      return axios.get(`${api}${router.app._route.params.promotionId}/codes/${payload}`).then((res) => {
        context.commit('GET_PROMOTIONCODE', res.data);
      });
    },
    removePromotionCode: (context, payload) => {
      return axios.delete(`${api}${router.app._route.params.promotionId}/codes/${payload}`).then(() => {
        context.dispatch('getPromotionCodeList');
      });
    },
    //                               ACTIVATIONS
    getPromotionActivations(context, { search, page, sort }) {
      if (!!search || page > 1 || !!sort.length) {
        if (
          `${router.app._route.path}${!!search ? `?src=${search}&` : '?'}${!!sort.length ? `sort=${sort}&` : ''}page=${page || 1}` !=
          router.app._route.fullPath
        ) {
          router.push(
            `${router.app._route.path}${!!search ? `?src=${search}&` : '?'}${!!sort.length ? `sort=${sort}&` : ''}page=${page || 1}`
          );
        }
        const sortValue = [];
        for (var i = 0; i < sort.length; i++) {
          sortValue.push(`sort=${sort[i]}&`);
        }
        return axios
          .get(
            `${api}${router.app._route.params.promotionId}/activations/${!!search ? `?src=${search}&` : '?'}${
              sortValue.join('') || ''
            }page=${page - 1 || 0}`
          )
          .then((res) => {
            context.commit('GET_PROMOTIONACTIVATIONS', res.data);
          });
      } else {
        if (router.app._route.path != router.app._route.fullPath) {
          router.push(`${router.app._route.path}`);
        }
        return axios.get(`${api}${router.app._route.params.promotionId}/activations/`).then((res) => {
          context.commit('GET_PROMOTIONACTIVATIONS', res.data);
        });
      }
    },
    setPromotionActivations: (context) => {
      context.commit('GET_PROMOTIONACTIVATIONS', {});
    },
    //                               CODE GROUP
    getPromotionGroupList: (context) => {
      return axios.get(`${apiG}`).then((res) => {
        context.commit('GET_PROMOTIONGROUPLIST', res.data);
      });
    },
    setPromotionGroupList: (context) => {
      context.commit('GET_PROMOTIONGROUPLIST', []);
    },
    createPromotionGroup: (context, payload) => {
      return axios.post(`${apiG}`, payload).then(() => {
        context.dispatch('getPromotionGroupList');
      });
    },
    getPromotionGroup: (context, payload) => {
      return axios.get(`${apiG}${payload}`).then((res) => {
        context.commit('GET_PROMOTIONGROUP', res.data);
      });
    },
    setPromotionGroup: (context) => {
      context.commit('GET_PROMOTIONGROUP', {});
    },
    removePromotionGroup: (context, payload) => {
      return axios.delete(`${apiG}${payload}`).then(() => {
        context.dispatch('getPromotionCodeList');
      });
    },
    //                               CARD BINS
    getCardBinsList: (context) => {
      return axios.get(`${api}${router.app._route.params.promotionId}/card-bins/`).then((res) => {
        context.commit('GET_CARDBINSLIST', res.data);
      });
    },
    setCardBinsList: (context) => {
      context.commit('GET_CARDBINSLIST', []);
    },
    cteateCardBinsItem: (context, payload) => {
      return axios.post(`${api}${router.app._route.params.promotionId}/card-bins/`, payload);
    },
    updateCardBinsItem: (context, { id, data }) => {
      return axios.put(`${api}${router.app._route.params.promotionId}/card-bins/${id}`, data);
    },
    removeCardBinsItem: (context, payload) => {
      return axios.delete(`${api}${router.app._route.params.promotionId}/card-bins/${payload}`);
    },
  },
  mutations: {
    GET_PROMOTIONSLIST: (state, payload) => {
      state.promotionsList = payload;
      return state.promotionsList;
    },
    GET_PROMOTIONITEM: (state, payload) => {
      state.promotionItem = payload;
      return state.promotionItem;
    },
    GET_PROMOTIONCODELIST: (state, payload) => {
      state.promotionCodeList = payload;
      return state.promotionCodeList;
    },
    GET_PROMOTIONCODE: (state, payload) => {
      state.promotionCode = payload;
      return state.promotionCode;
    },
    GET_PROMOTIONACTIVATIONS: (state, payload) => {
      state.promotionActivations = payload;
      return state.promotionActivations;
    },
    GET_PROMOTIONGROUPLIST: (state, payload) => {
      state.promotionGroupList = payload;
      return state.promotionGroupList;
    },
    GET_PROMOTIONGROUP: (state, payload) => {
      state.promotionGroup = payload;
      return state.promotionGroup;
    },
    GET_CARDBINSLIST: (state, payload) => {
      state.cardBinsList = payload;
      return state.cardBinsList;
    },
  },
  getters: {
    promotionsList(state) {
      return state.promotionsList;
    },
    promotionItem(state) {
      return state.promotionItem;
    },
    promotionCodeList(state) {
      return state.promotionCodeList;
    },
    promotionCode(state) {
      return state.promotionCode;
    },
    promotionActivations(state) {
      return state.promotionActivations;
    },
    promotionGroupList(state) {
      return state.promotionGroupList;
    },
    promotionGroup(state) {
      return state.promotionGroup;
    },
    cardBinsList(state) {
      return state.cardBinsList;
    },
  },
};
