import '@/plugins/axios';
import router from '@/router/index';

const api = '/platform/api/v1/dw-refill-codes/';
const apiG = '/platform/api/v1/dw-refill-code-groups/';

export default {
  state: {
    refillCodesList: {},
    refillCodeItem: {},
    refillCodeGroup: [],
  },
  actions: {
    getRefillCodesList(context, { search, page, name, available }) {
      if (!!search || page > 1 || name != 'all' || available != 'all') {
        if (
          `${router.app._route.path}${name != 'all' ? `?group=${name}&` : '?'}${available != 'all' ? `available=${available}&` : ''}${
            !!search ? `src=${search}&` : ''
          }page=${page || 1}` != router.app._route.fullPath
        ) {
          router.push(
            `${router.app._route.path}${name != 'all' ? `?group=${name}&` : '?'}${available != 'all' ? `available=${available}&` : ''}${
              !!search ? `src=${search}&` : ''
            }page=${page || 1}`
          );
        }
        return axios
          .get(
            `${api}${name != 'all' ? `?group=${name}&` : '?'}${available != 'all' ? `available=${available}&` : ''}${
              !!search ? `src=${search}&` : ''
            }page=${page - 1 || 0}`
          )
          .then((res) => {
            context.commit('GET_REFILLCODESLIST', res.data);
          });
      } else {
        if (router.app._route.path != router.app._route.fullPath) {
          router.push(`${router.app._route.path}`);
        }
        return axios.get(`${api}?page=${page - 1 || 0}`).then((res) => {
          context.commit('GET_REFILLCODESLIST', res.data);
        });
      }
    },
    setRefillCodesList: (context) => {
      context.commit('GET_REFILLCODESLIST', {});
    },
    createRefillCode(context, payload) {
      return axios.post(api, payload).then((res) => {
        router.push(`/refill-codes/${res.data.id}`);
      });
    },
    getRefillCodeItem: (context) => {
      return axios.get(`${api}${router.app._route.params.refillId}`).then((res) => {
        res.data.amount = res.data.amount / 100;
        context.commit('GET_REFILLCODEITEM', res.data);
      });
    },
    updateRefillCodeItem: (context, payload) => {
      return axios.put(`${api}${router.app._route.params.refillId}`, payload).then((res) => {
        res.data.amount = res.data.amount / 100;
        context.commit('GET_REFILLCODEITEM', res.data);
      });
    },
    removeRefillCodeItem: (context) => {
      return axios.delete(`${api}${router.app._route.params.refillId}`).then(() => {
        router.push('/refill-codes');
      });
    },
    setRefillCodeItem: (context) => {
      context.commit('GET_REFILLCODEITEM', {});
    },
    //                               GROUP
    getRefillCodeGroup: (context) => {
      return axios.get(`${apiG}`).then((res) => {
        context.commit('GET_REFILLCODEGROUP', res.data);
      });
    },
    createRefillCodeGroup(context, payload) {
      return axios.post(apiG, payload).then(() => {
        context.dispatch('getRefillCodeGroup');
      });
    },
    removeRefillCodeGroup(context, payload) {
      return axios.post(`${apiG}${payload}`).then(() => {
        context.dispatch('getRefillCodeGroup');
      });
    },
    setRefillCodeGroup: (context) => {
      context.commit('GET_REFILLCODEITEM', []);
    },
  },
  mutations: {
    GET_REFILLCODESLIST: (state, payload) => {
      state.refillCodesList = payload;
      return state.refillCodesList;
    },
    GET_REFILLCODEITEM: (state, payload) => {
      state.refillCodeItem = payload;
      return state.refillCodeItem;
    },
    GET_REFILLCODEGROUP: (state, payload) => {
      state.refillCodeGroup = payload;
      return state.refillCodeGroup;
    },
  },
  getters: {
    refillCodesList(state) {
      return state.refillCodesList;
    },
    refillCodeItem(state) {
      return state.refillCodeItem;
    },
    refillCodeGroup(state) {
      return state.refillCodeGroup;
    },
  },
};
