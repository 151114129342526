import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: true,
      light: {
        primary: {
          darken3: '#225792',
          darken2: '#133052',
          darken1: '#1c4778',
          base: '#225792',
          lighten1: '#3383de',
          lighten2: '#3b96ff',
          lighten3: '#ebf4ff',
        },
        gray: {
          darken5: '#3d3d3d',
          base: '#808080',
          lighten1: '#b8b8b8',
          lighten2: '#d6d6d6',
          lighten3: '#ebebeb',
          lighten4: '#f5f5f5',
        },
        error: {
          base: '#d44339',
          lighten5: '#f2e9e9',
        },
        success: {
          base: '#09873d',
          lighten5: '#cee7d8',
        },
        warning: {
          darken1: '#f2a922',
          base: '#debf1d',
          lighten5: '#fffcf2',
        },
        accent: '#fafafa',
        secondary: '#e84569',
        text: '#4a4a4a',
        disabled: '#939393',
      },
    },
  },
});
