import '@/plugins/axios';
import router from '@/router/index';

const api = '/platform/api/v1/experience-bookings/';

export default {
  state: {
    bookingExperienceList: {},
    bookingExperience: { customer: {}, travel_experience: {}, destinations: [] },
    bookingExperienceAttachments: [],
  },
  actions: {
    getBookingExperienceList(context, { search, page, sort, status, lealStatus, customer }) {
      if (!!search || page > 1 || !!sort.length || status != 'all' || lealStatus != 'all' || customer != 'all') {
        if (router.app._route.name != 'Home') {
          if (
            `${router.app._route.path}${customer != 'all' ? `?customer=${customer}&` : '?'}${status != 'all' ? `status=${status}&` : ''}${
              lealStatus != 'all' ? `leal_status=${lealStatus}&` : ''
            }${!!sort.length ? `sort=${sort}&` : ''}${!!search ? `src=${search}&` : ''}page=${page || 1}` != router.app._route.fullPath
          ) {
            router.push(
              `${router.app._route.path}${customer != 'all' ? `?customer=${customer}&` : '?'}${status != 'all' ? `status=${status}&` : ''}${
                lealStatus != 'all' ? `leal_status=${lealStatus}&` : ''
              }${!!sort.length ? `sort=${sort}&` : ''}${!!search ? `src=${search}&` : ''}page=${page || 1}`
            );
          }
        }
        const sortValue = [];
        for (var i = 0; i < sort.length; i++) {
          sortValue.push(`sort=${sort[i]}&`);
        }
        return axios
          .get(
            `${api}${customer != 'all' ? `?customer=${customer}&` : '?'}${status != 'all' ? `status=${status}&` : ''}${
              lealStatus != 'all' ? `leal_status=${lealStatus}&` : ''
            }${sortValue.join('') || ''}${!!search ? `src=${search}&` : ''}page=${page - 1 || 0}`
          )
          .then((res) => {
            context.commit('GET_BOOKINGEXPERIENCELIST', res.data);
          });
      } else {
        if (router.app._route.name != 'Home') {
          if (router.app._route.path != router.app._route.fullPath) {
            router.push(`${router.app._route.path}`);
          }
        }
        return axios.get(`${api}`).then((res) => {
          context.commit('GET_BOOKINGEXPERIENCELIST', res.data);
        });
      }
    },
    setBookingExperienceList: (context) => {
      context.commit('GET_BOOKINGEXPERIENCELIST', {});
    },
    getBookingExperienceItem(context) {
      return axios.get(`${api}${router.app._route.params.bookingId}`).then((res) => {
        context.commit('GET_BOOKINGEXPERIENCE', res.data);
      });
    },
    actionBookingExperienceItem(context, payload) {
      return axios.post(`${api}${router.app._route.params.bookingId}/action`, payload).then((res) => {
        context.commit('GET_BOOKINGEXPERIENCE', res.data);
      });
    },
    getBookingExperienceItemInvoice(context) {
      return axios.get(`${api}${router.app._route.params.bookingId}/invoice`, { responseType: 'arraybuffer' }).then((res) => {
        const blob = new Blob([res.data], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'invoice-experience.pdf';
        link.click();
      });
    },
    setBookingExperienceItem: (context) => {
      context.commit('GET_BOOKINGEXPERIENCE', { customer: {}, travel_experience: {}, destinations: [] });
    },
    getBookingExperienceItemAttachments(context) {
      return axios.get(`${api}${router.app._route.params.bookingId}/attachments/`).then((res) => {
        context.commit('GET_BOOKINGEXPERIENCEATTACHMENTS', res.data);
      });
    },
    setBookingExperienceItemAttachments(context) {
      context.commit('GET_BOOKINGEXPERIENCEATTACHMENTS', []);
    },
    addBookingExperienceItemAttachments(context, payload) {
      return axios({
        method: 'post',
        url: `${api}${router.app._route.params.bookingId}/attachments/`,
        data: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    removeBookingExperienceItemAttachments(context, payload) {
      return axios.delete(`${api}${router.app._route.params.bookingId}/attachments/${payload}`);
    },
  },
  mutations: {
    GET_BOOKINGEXPERIENCELIST: (state, payload) => {
      state.bookingExperienceList = payload;
      return state.bookingExperienceList;
    },
    GET_BOOKINGEXPERIENCE: (state, payload) => {
      state.bookingExperience = payload;
      return state.bookingExperience;
    },
    GET_BOOKINGEXPERIENCEATTACHMENTS: (state, payload) => {
      state.bookingExperienceAttachments = payload;
      return state.bookingExperienceAttachments;
    },
  },
  getters: {
    bookingExperienceList(state) {
      return state.bookingExperienceList;
    },
    bookingExperience(state) {
      return state.bookingExperience;
    },
    bookingExperienceAttachments(state) {
      return state.bookingExperienceAttachments;
    },
  },
};
