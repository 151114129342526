import '@/plugins/axios';
import router from '@/router/index';

const api = '/platform/api/v1/trips-groups/';

export default {
  state: {
    tripsGroupsShort: [],
    tripsGroupsList: [],
    tripsGroup: { filters: [] },
  },
  actions: {
    getTripsGroupsShort(context) {
      return axios.get(`${api}short/`).then((res) => {
        context.commit('GET_TRIPSGROUPSSHORT', res.data);
      });
    },
    getTripsGroupsList(context, payload) {
      return axios.get(`${api}${payload}`).then((res) => {
        context.commit('GET_TRIPSGROUPSLIST', res.data);
      });
    },
    setTripsGroupsList: (context) => {
      context.commit('GET_TRIPSGROUPSSHORT', []);
      context.commit('GET_TRIPSGROUPSLIST', []);
    },
    createTripsGroup(context, payload) {
      return axios.post(api, payload).then((res) => {
        router.push(`/trip-groups/${res.data.id}`);
      });
    },
    getTripsGroupItem(context) {
      return axios.get(`${api}${router.app._route.params.tripGroupsId}`).then((res) => {
        context.commit('GET_TRIPSGROUP', res.data);
      });
    },
    setTripsGroupItem: (context) => {
      context.commit('GET_TRIPSGROUP', { filters: [] });
    },
    updateTripsGroupItem(context, payload) {
      return axios.put(`${api}${payload.id || router.app._route.params.tripGroupsId}`, payload).then((res) => {
        context.commit('GET_TRIPSGROUP', res.data);
      });
    },
    deleteTripsGroupItem(context, payload) {
      return axios.delete(`${api}${payload || router.app._route.params.tripGroupsId}`);
    },
    setFiltersTripsGroup(context, payload) {
      return axios({
        method: 'post',
        url: `${api}${router.app._route.params.tripGroupsId}/filters/`,
        data: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      }).then((res) => {
        context.commit('GET_TRIPSGROUP', res.data);
      });
    },
    updateFiltersTripsGroup(context, { data, id }) {
      return axios.put(`${api}${router.app._route.params.tripGroupsId}/filters/${id}`, data).then((res) => {
        context.commit('GET_TRIPSGROUP', res.data);
      });
    },
    deleteFiltersTripsGroup(context, payload) {
      return axios.delete(`${api}${router.app._route.params.tripGroupsId}/filters/${payload}`);
    },
    updateFiltersPictureTripsGroup(context, { data, id }) {
      return axios({
        method: 'put',
        url: `${api}${router.app._route.params.tripGroupsId}/filters/${id}/picture`,
        data: data,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      }).then((res) => {
        context.commit('GET_TRIPSGROUP', res.data);
      });
    },
    updateFiltersPictureMTripsGroup(context, { data, id }) {
      return axios({
        method: 'put',
        url: `${api}${router.app._route.params.tripGroupsId}/filters/${id}/picture-medium`,
        data: data,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      }).then((res) => {
        context.commit('GET_TRIPSGROUP', res.data);
      });
    },
    updateFiltersPictureSTripsGroup(context, { data, id }) {
      return axios({
        method: 'put',
        url: `${api}${router.app._route.params.tripGroupsId}/filters/${id}/picture-small`,
        data: data,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      }).then((res) => {
        context.commit('GET_TRIPSGROUP', res.data);
      });
    },
    deleteFiltersPictureMTripsGroup(context, payload) {
      return axios.delete(`${api}${router.app._route.params.tripGroupsId}/filters/${payload}/picture-medium`);
    },
    deleteFiltersPictureSTripsGroup(context, payload) {
      return axios.delete(`${api}${router.app._route.params.tripGroupsId}/filters/${payload}/picture-small`);
    },
  },
  mutations: {
    GET_TRIPSGROUPSSHORT: (state, payload) => {
      state.tripsGroupsShort = payload;
      return state.tripsGroupsShort;
    },
    GET_TRIPSGROUPSLIST: (state, payload) => {
      state.tripsGroupsList = payload;
      return state.tripsGroupsList;
    },
    GET_TRIPSGROUP: (state, payload) => {
      state.tripsGroup = payload;
      return state.tripsGroup;
    },
  },
  getters: {
    tripsGroupsShort(state) {
      return state.tripsGroupsShort;
    },
    tripsGroupsList(state) {
      return state.tripsGroupsList;
    },
    tripsGroup(state) {
      return state.tripsGroup;
    },
  },
};
