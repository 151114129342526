import '@/plugins/axios';
import router from '@/router/index';

const api = '/platform/api/v1/gift-voucher-offers/';

export default {
  state: {
    voucherOffersList: {},
    voucherOffer: { vat_option: {} },
  },
  actions: {
    getVoucherOffersList(context, { search, page, status }) {
      if (!!search || page > 1 || status != 'all') {
        if (
          `${router.app._route.path}${!!search ? `?src=${search}&` : '?'}${status != 'all' ? `status=${status}&` : ''}page=${page || 1}` !=
          router.app._route.fullPath
        ) {
          router.push(
            `${router.app._route.path}${!!search ? `?src=${search}&` : '?'}${status != 'all' ? `status=${status}&` : ''}page=${page || 1}`
          );
        }
        return axios
          .get(`${api}?${!!search ? `src=${search}&` : ''}${status != 'all' ? `status=${status}&` : ''}page=${page - 1 || 0}`)
          .then((res) => {
            context.commit('GET_VOUCHEROFFERSLIST', res.data);
          });
      } else {
        if (router.app._route.path != router.app._route.fullPath) {
          router.push(`${router.app._route.path}`);
        }
        return axios.get(`${api}?page=${page - 1 || 0}`).then((res) => {
          context.commit('GET_VOUCHEROFFERSLIST', res.data);
        });
      }
    },
    setVoucherOffersList: (context) => {
      context.commit('GET_VOUCHEROFFERSLIST', {});
    },
    createVoucherOfferItem(context, payload) {
      return axios({
        method: 'post',
        url: `${api}`,
        data: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    getVoucherOfferItem(context) {
      return axios.get(`${api}${router.app._route.params.voucherOfferId}`).then((res) => {
        if (res.data.picture) {
          res.data.picture.status == 'processing' ? context.dispatch('getVoucherOfferItem', res.data.id) : '';
        }
        res.data.priceR = res.data.price / 100;
        context.commit('GET_VOUCHEROFFER', res.data);
      });
    },
    setVoucherOfferItem: (context) => {
      context.commit('GET_VOUCHEROFFER', { vat_option: {} });
    },
    updateVoucherOfferItem(context, payload) {
      return axios({
        method: 'put',
        url: `${api}${router.app._route.params.voucherOfferId}`,
        data: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      }).then((res) => {
        context.dispatch('getVoucherOfferItem', res.data.id);
      });
    },
    removeVoucherOfferItem(context) {
      return axios.delete(`${api}${router.app._route.params.voucherOfferId}`).then((res) => {
        router.push('/gift-voucher-offers');
      });
    },
    statusVoucherOfferItem(context, payload) {
      return axios.post(`${api}${router.app._route.params.voucherOfferId}/status`, payload);
    },
  },
  mutations: {
    GET_VOUCHEROFFERSLIST: (state, payload) => {
      state.voucherOffersList = payload;
      return state.voucherOffersList;
    },
    GET_VOUCHEROFFER: (state, payload) => {
      state.voucherOffer = payload;
      return state.voucherOffer;
    },
  },
  getters: {
    voucherOffersList(state) {
      return state.voucherOffersList;
    },
    voucherOffer(state) {
      return state.voucherOffer;
    },
  },
};
