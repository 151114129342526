import '@/plugins/axios';
import router from '@/router/index';

const api = '/platform/api/v1/payments/';

export default {
  state: {
    paymentsList: {},
    payments: { customer: {} },
    paymentProviders: [],
  },
  actions: {
    getPaymentsList(context, { search, page, sort, status, customer, payMethod, from, to }) {
      if (!!search || page > 1 || !!sort.length || status != 'all' || customer != 'all' || payMethod != 'all' || !!from || !!to) {
        if (router.app._route.name != 'Home') {
          if (
            `${router.app._route.path}${customer != 'all' ? `?customer=${customer}&` : '?'}${status != 'all' ? `status=${status}&` : ''}${
              payMethod != 'all' ? `payment_method=${payMethod}&` : ''
            }${!!from ? `date_from=${from}&` : ''}${!!to ? `date_to=${to}&` : ''}${!!sort.length ? `sort=${sort}&` : ''}${
              !!search ? `src=${search}&` : ''
            }page=${page || 1}` != router.app._route.fullPath
          ) {
            router.push(
              `${router.app._route.path}${customer != 'all' ? `?customer=${customer}&` : '?'}${status != 'all' ? `status=${status}&` : ''}${
                payMethod != 'all' ? `payment_method=${payMethod}&` : ''
              }${!!from ? `date_from=${from}&` : ''}${!!to ? `date_to=${to}&` : ''}${!!sort.length ? `sort=${sort}&` : ''}${
                !!search ? `src=${search}&` : ''
              }page=${page || 1}`
            );
          }
        }
        const sortValue = [];
        for (var i = 0; i < sort.length; i++) {
          sortValue.push(`sort=${sort[i]}&`);
        }
        return axios
          .get(
            `${api}${customer != 'all' ? `?customer=${customer}&` : '?'}${status != 'all' ? `status=${status}&` : ''}${
              payMethod != 'all' ? `payment_method=${payMethod}&` : ''
            }${!!from ? `date_from=${from}&` : ''}${!!to ? `date_to=${to}&` : ''}${sortValue.join('') || ''}${
              !!search ? `src=${search}&` : ''
            }page=${page - 1 || 0}`
          )
          .then((res) => {
            context.commit('GET_PEYMENTSLIST', res.data);
          });
      } else {
        if (router.app._route.name != 'Home') {
          if (router.app._route.path != router.app._route.fullPath) {
            router.push(`${router.app._route.path}`);
          }
        }
        return axios.get(`${api}`).then((res) => {
          context.commit('GET_PEYMENTSLIST', res.data);
        });
      }
    },
    setPaymentsList: (context) => {
      context.commit('GET_BOOKINGLIST', {});
    },
    getPaymentsItem(context) {
      return axios.get(`${api}${router.app._route.params.paymentsId}`).then((res) => {
        context.commit('GET_PEYMENTS', res.data);
      });
    },
    setPaymentsItem: (context) => {
      context.commit('GET_PEYMENTS', { customer: {} });
    },
    getPaymentProviders(context) {
      return axios.get('/platform/api/v1/payment-providers/').then((res) => {
        context.commit('GET_PEYMENTPROVIDERS', res.data);
      });
    },
    setPaymentProviders: (context) => {
      context.commit('GET_PEYMENTPROVIDERS', []);
    },
  },
  mutations: {
    GET_PEYMENTSLIST: (state, payload) => {
      state.paymentsList = payload;
      return state.paymentsList;
    },
    GET_PEYMENTS: (state, payload) => {
      state.payments = payload;
      return state.payments;
    },
    GET_PEYMENTPROVIDERS: (state, payload) => {
      state.paymentProviders = payload;
      return state.paymentProviders;
    },
  },
  getters: {
    paymentsList(state) {
      return state.paymentsList;
    },
    payments(state) {
      return state.payments;
    },
    paymentProviders(state) {
      return state.paymentProviders;
    },
  },
};
