import '@/plugins/axios';

const api = '/platform/api/v1/statistics/';

export default {
  state: {
    statisticsCounters: {},
    statisticsCustomers: {},
    statisticsBookings: {},
    statisticsProducts: {},
  },
  actions: {
    getStatisticsCounters(context) {
      return axios.get(`${api}counters`).then((res) => {
        context.commit('GET_STATISTICSCOUNTERS', res.data);
      });
    },
    setStatisticsCounters: (context) => {
      context.commit('GET_STATISTICSCOUNTERS', {});
    },
    getStatisticsCustomers(context, payload) {
      return axios.get(`${api}customers?period=${payload}`).then((res) => {
        context.commit('GET_STATISTICSCUSTOMERS', res.data);
      });
    },
    setStatisticsCustomers: (context) => {
      context.commit('GET_STATISTICSCUSTOMERS', {});
    },
    getStatisticsBookings(context, payload) {
      return axios.get(`${api}bookings?period=${payload}`).then((res) => {
        context.commit('GET_STATISTICSBOOKINGS', res.data);
      });
    },
    setStatisticsBookings: (context) => {
      context.commit('GET_STATISTICSBOOKINGS', {});
    },
    getStatisticsProducts(context, payload) {
      return axios.get(`${api}product-orders?period=${payload}`).then((res) => {
        context.commit('GET_STATISTICSPRODUCTS', res.data);
      });
    },
    setStatisticsProducts: (context) => {
      context.commit('GET_STATISTICSPRODUCTS', {});
    },
  },
  mutations: {
    GET_STATISTICSCOUNTERS: (state, payload) => {
      state.statisticsCounters = payload;
      return state.statisticsCounters;
    },
    GET_STATISTICSCUSTOMERS: (state, payload) => {
      state.statisticsCustomers = payload;
      return state.statisticsCustomers;
    },
    GET_STATISTICSBOOKINGS: (state, payload) => {
      state.statisticsBookings = payload;
      return state.statisticsBookings;
    },
    GET_STATISTICSPRODUCTS: (state, payload) => {
      state.statisticsProducts = payload;
      return state.statisticsProducts;
    },
  },
  getters: {
    statisticsCounters(state) {
      return state.statisticsCounters;
    },
    statisticsCustomers(state) {
      return state.statisticsCustomers;
    },
    statisticsBookings(state) {
      return state.statisticsBookings;
    },
    statisticsProducts(state) {
      return state.statisticsProducts;
    },
  },
};
