import '@/plugins/axios';
import router from '@/router/index';

const api = '/platform/api/v1/experts/';

export default {
  state: {
    expertsListShort: [],
    expertsList: {},
    expert: {},
  },
  actions: {
    getExpertShort(context, payload) {
      return axios.get(`${api}short/${payload ? `?src=${payload}` : ''}`).then((res) => {
        context.commit('GET_EXPERTSLISTSHORT', res.data);
      });
    },
    setExpertShort: (context) => {
      context.commit('GET_EXPERTSLISTSHORT', []);
    },
    getExpertsList(context, { search, page }) {
      if (!!search || page > 1) {
        if (`${router.app._route.path}${!!search ? `?src=${search}&` : '?'}page=${page || 1}` != router.app._route.fullPath) {
          router.push(`${router.app._route.path}${!!search ? `?src=${search}&` : '?'}page=${page || 1}`);
        }
        return axios.get(`${api}${!!search ? `?src=${search}&` : '?'}page=${page - 1 || 0}`).then((res) => {
          context.commit('GET_EXPERTSLIST', res.data);
        });
      } else {
        if (router.app._route.path != router.app._route.fullPath) {
          router.push(`${router.app._route.path}`);
        }
        return axios.get(`${api}?page=${page - 1 || 0}`).then((res) => {
          context.commit('GET_EXPERTSLIST', res.data);
        });
      }
    },
    setExpertsList: (context) => {
      context.commit('GET_EXPERTSLIST', {});
    },
    createExpetr(context, payload) {
      return axios.post(api, payload).then((res) => {
        router.push(`/experts/${res.data.id}`);
      });
    },
    getExpertItem(context) {
      return axios.get(`${api}${router.app._route.params.expertId}`).then((res) => {
        context.commit('GET_EXPERT', res.data);
      });
    },
    setExpertItem: (context) => {
      context.commit('GET_EXPERT', {});
    },
    updateExpertItem(context, payload) {
      return axios.put(`${api}${router.app._route.params.expertId}`, payload).then((res) => {
        context.commit('GET_EXPERT', res.data);
      });
    },
    setPhotoExpert(context, payload) {
      return axios({
        method: 'post',
        url: `${api}${router.app._route.params.expertId}/picture`,
        data: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      }).then((res) => {
        context.commit('GET_EXPERT', res.data);
      });
    },
    deletePhotoExpert(context) {
      return axios.delete(`${api}${router.app._route.params.expertId}/picture`);
    },
    setBgExpert(context, payload) {
      return axios({
        method: 'post',
        url: `${api}${router.app._route.params.expertId}/bg-picture`,
        data: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      }).then((res) => {
        context.commit('GET_EXPERT', res.data);
      });
    },
    deleteBgExpert(context) {
      return axios.delete(`${api}${router.app._route.params.expertId}/bg-picture`);
    },
    sendEmailExpert(context) {
      return axios.post(`${api}${router.app._route.params.expertId}/send-email-confirmation`).then((res) => {
        context.commit('GET_EXPERT', res.data);
      });
    },
    resetPassExpert(context) {
      return axios.post(`${api}${router.app._route.params.expertId}/reset-password`).then(() => {
        context.dispatch('getExpertItem');
      });
    },
    hiddenExpert(context, payload) {
      return axios.post(`${api}${router.app._route.params.expertId}/hidden`, payload).then((res) => {
        context.commit('GET_EXPERT', res.data);
      });
    },
  },
  mutations: {
    GET_EXPERTSLISTSHORT: (state, payload) => {
      state.expertsListShort = payload;
      return state.expertsListShort;
    },
    GET_EXPERTSLIST: (state, payload) => {
      state.expertsList = payload;
      return state.expertsList;
    },
    GET_EXPERT: (state, payload) => {
      state.expert = payload;
      return state.expert;
    },
  },
  getters: {
    expertsListShort(state) {
      return state.expertsListShort;
    },
    expertsList(state) {
      return state.expertsList;
    },
    expert(state) {
      return state.expert;
    },
  },
};
