import '@/plugins/axios';
import router from '@/router/index';

const api = '/platform/api/v1/product-vendors/';

export default {
  state: {
    vendorsShort: [],
    vendorsList: {},
    vendor: {},
    vendorSchedule: [],
  },
  actions: {
    getVendorsShort(context, payload) {
      return axios.get(`${api}short/${payload ? `?src=${payload}` : ''}`).then((res) => {
        context.commit('GET_VENDORSSHORT', res.data);
      });
    },
    getVendorsList(context, { search, page, status }) {
      if (!!search || page > 1 || status != 'all') {
        if (
          `${router.app._route.path}${!!search ? `?src=${search}&` : '?'}${status != 'all' ? `status=${status}&` : ''}page=${page || 1}` !=
          router.app._route.fullPath
        ) {
          router.push(
            `${router.app._route.path}${!!search ? `?src=${search}&` : '?'}${status != 'all' ? `status=${status}&` : ''}page=${page || 1}`
          );
        }
        return axios
          .get(`${api}${!!search ? `?src=${search}&` : '?'}${status != 'all' ? `status=${status}&` : ''}page=${page - 1 || 0}`)
          .then((res) => {
            context.commit('GET_VENDORSLIST', res.data);
          });
      } else {
        if (router.app._route.path != router.app._route.fullPath) {
          router.push(`${router.app._route.path}`);
        }
        return axios.get(`${api}?page=${page - 1 || 0}`).then((res) => {
          context.commit('GET_VENDORSLIST', res.data);
        });
      }
    },
    setVendorsList: (context) => {
      context.commit('GET_VENDORSSHORT', []);
      context.commit('GET_VENDORSLIST', {});
    },
    createVendor(context, payload) {
      return axios({
        method: 'post',
        url: `${api}`,
        data: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    getVendorItem(context, payload) {
      return axios.get(`${api}${payload ? payload : router.app._route.params.vendorId}`).then((res) => {
        context.commit('GET_VENDOR', res.data);
      });
    },
    setVendorItem: (context) => {
      context.commit('GET_VENDOR', {});
    },
    updateVendorItem(context, { id, data }) {
      return axios.put(`${api}${id ? id : router.app._route.params.vendorId}`, data).then((res) => {
        context.commit('GET_VENDOR', res.data);
      });
    },
    deleteVendorItem(context) {
      return axios.delete(`${api}${router.app._route.params.vendorId}`).then(() => {
        router.push('/gift-vendors');
      });
    },
    setPhotoVendor(context, { id, data }) {
      return axios({
        method: 'post',
        url: `${api}${id ? id : router.app._route.params.vendorId}/picture`,
        data: data,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      }).then((res) => {
        context.commit('GET_VENDOR', res.data);
      });
    },
    statusVendorItem(context, { id, data }) {
      return axios.post(`${api}${id ? id : router.app._route.params.vendorId}/status`, data).then((res) => {
        context.commit('GET_VENDOR', res.data);
      });
    },
    getVendorItemSchedule(context, payload) {
      return axios.get(`${api}${payload ? payload : router.app._route.params.vendorId}/schedule/`).then((res) => {
        res.data.push({ time_from: null, time_to: null });
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].id) {
            res.data[i].priceR = res.data[i].price / 100 || '';
          }
        }
        context.commit('GET_VENDORSCHEDULE', res.data);
      });
    },
    setVendorItemSchedule: (context) => {
      context.commit('GET_VENDORSCHEDULE', []);
    },
    addVendorItemSchedule(context, { id, data }) {
      return axios.post(`${api}${id ? id : router.app._route.params.vendorId}/schedule/`, data);
    },
    updateVendorItemSchedule(context, { vendor, id, data }) {
      return axios.put(`${api}${vendor ? vendor : router.app._route.params.vendorId}/schedule/${id}`, data);
    },
    deleteVendorItemSchedule(context, { id, data }) {
      return axios.delete(`${api}${id ? id : router.app._route.params.vendorId}/schedule/${data}`);
    },
  },
  mutations: {
    GET_VENDORSSHORT: (state, payload) => {
      state.vendorsShort = payload;
      return state.vendorsShort;
    },
    GET_VENDORSLIST: (state, payload) => {
      state.vendorsList = payload;
      return state.vendorsList;
    },
    GET_VENDOR: (state, payload) => {
      state.vendor = payload;
      return state.vendor;
    },
    GET_VENDORSCHEDULE: (state, payload) => {
      state.vendorSchedule = payload;
      return state.vendorSchedule;
    },
  },
  getters: {
    vendorsShort(state) {
      return state.vendorsShort;
    },
    vendorsList(state) {
      return state.vendorsList;
    },
    vendor(state) {
      return state.vendor;
    },
    vendorSchedule(state) {
      return state.vendorSchedule;
    },
  },
};
