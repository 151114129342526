import '@/plugins/axios';

const api = '/platform/api/v1/inspirations/';

export default {
  state: {
    inspirationsList: [],
  },
  actions: {
    getInspirationsList(context) {
      return axios.get(`${api}`).then((res) => {
        context.commit('GET_INSPIRATIONSLIST', res.data);
      });
    },
    setInspirationsList(context) {
      context.commit('GET_INSPIRATIONSLIST', []);
    },
    createInspirations(context, payload) {
      return axios({
        method: 'post',
        url: api,
        data: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    getInspirationsItem(context, payload) {
      return axios.get(`${api}${payload}`);
    },
    updateInspirationsItem(context, { id, data }) {
      return axios.put(`${api}${id}`, data);
    },
    updateInspirationsItemPicture(context, { id, data }) {
      return axios({
        method: 'post',
        url: `${api}${id}/picture`,
        data: data,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    updateInspirationsItemOrder(context, { id, data }) {
      return axios.post(`${api}${id}/order`, data);
    },
    removeInspirationsItem(context, payload) {
      return axios.delete(`${api}${payload}`);
    },
  },
  mutations: {
    GET_INSPIRATIONSLIST: (state, payload) => {
      state.inspirationsList = payload;
      return state.inspirationsList;
    },
  },
  getters: {
    inspirationsList(state) {
      return state.inspirationsList;
    },
  },
};
