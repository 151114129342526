import '@/plugins/axios';
import router from '@/router/index';

const api = '/platform/api/v1/trip-requests/';

export default {
  state: {
    requestsList: {},
    requests: {
      person: {},
      destinations: [],
      departure_country: {},
      departure_city: {},
      departure_airport: {},
      plan: {
        accommodations: [],
        components: [],
        trip: { departure: { country: {}, city: {} }, destinations: [{ destination: { city: {}, country: {} } }] },
      },
    },
  },
  actions: {
    getRequestsList(context, { search, page, sort, status }) {
      if (!!search || page > 1 || !!sort.length || status != 'all') {
        if (router.app._route.name != 'Home') {
          if (
            `${router.app._route.path}${status != 'all' ? `?status=${status}&` : '?'}${!!sort.length ? `sort=${sort}&` : ''}${
              !!search ? `src=${search}&` : ''
            }page=${page || 1}` != router.app._route.fullPath
          ) {
            router.push(
              `${router.app._route.path}${status != 'all' ? `?status=${status}&` : '?'}${!!sort.length ? `sort=${sort}&` : ''}${
                !!search ? `src=${search}&` : ''
              }page=${page || 1}`
            );
          }
        }
        const sortValue = [];
        for (var i = 0; i < sort.length; i++) {
          sortValue.push(`sort=${sort[i]}&`);
        }
        return axios
          .get(
            `${api}${status != 'all' ? `?status=${status}&` : '?'}${sortValue.join('') || ''}${!!search ? `src=${search}&` : ''}page=${
              page - 1 || 0
            }`
          )
          .then((res) => {
            context.commit('GET_REQUESTSLIST', res.data);
          });
      } else {
        if (router.app._route.name != 'Home') {
          if (router.app._route.path != router.app._route.fullPath) {
            router.push(`${router.app._route.path}`);
          }
        }
        return axios.get(`${api}`).then((res) => {
          context.commit('GET_REQUESTSLIST', res.data);
        });
      }
    },
    setRequestsList: (context) => {
      context.commit('GET_REQUESTSLIST', {});
    },
    supportFunctionRequest(context, payload) {
      const data = payload;
      if (data.plan) {
        if (data.status != 'finished') {
          data.plan.components.push({ id: 1, included: true, component: {} });
        }
        data.plan.priceR = data.plan.price / 100;
      }
      context.commit('GET_REQUESTS', data);
    },
    getRequestsItem(context, payload) {
      return axios.get(`${api}${router.app._route.params.requestsId || payload}`).then((res) => {
        context.dispatch('supportFunctionRequest', res.data);
      });
    },
    setRequestsItem: (context) => {
      context.commit('GET_REQUESTS', {
        person: {},
        destinations: [],
        departure_country: {},
        departure_city: {},
        departure_airport: {},
        plan: {
          accommodations: [],
          components: [],
          trip: { departure: { country: {}, city: {} }, destinations: [{ destination: { city: {}, country: {} } }] },
        },
      });
    },
    rejectRequestsItem(context, payload) {
      return axios.post(`${api}${router.app._route.params.requestsId || payload}/reject`).then((res) => {
        context.dispatch('supportFunctionRequest', res.data);
      });
    },
    processedRequestsItem(context, payload) {
      return axios.post(`${api}${router.app._route.params.requestsId || payload}/processed`).then((res) => {
        context.dispatch('supportFunctionRequest', res.data);
      });
    },
    createRequestsItem(context, { data, id }) {
      return axios.post(`${api}${router.app._route.params.requestsId || id}/plan`, data).then((res) => {
        context.dispatch('supportFunctionRequest', res.data);
      });
    },
    updateRequestsItem(context, { data, id }) {
      return axios.put(`${api}${router.app._route.params.requestsId || id}/plan`, data).then((res) => {
        context.dispatch('supportFunctionRequest', res.data);
      });
    },
    createRequestsItemCom(context, { data, id }) {
      return axios.post(`${api}${router.app._route.params.requestsId || id}/plan/components/`, data).then((res) => {
        context.dispatch('supportFunctionRequest', res.data);
      });
    },
    updateRequestsItemCom(context, { id, data, idr }) {
      return axios.put(`${api}${router.app._route.params.requestsId || idr}/plan/components/${id}`, data).then((res) => {
        context.dispatch('supportFunctionRequest', res.data);
      });
    },
    removeRequestsItemCom(context, { id, idr }) {
      return axios.delete(`${api}${router.app._route.params.requestsId || idr}/plan/components/${id}`).then(() => {
        context.dispatch('getRequestsItem');
      });
    },
    setRequestsItemAccommo(context, { data, id }) {
      return axios.post(`${api}${router.app._route.params.requestsId || id}/plan/accommodations`, data).then((res) => {
        context.dispatch('supportFunctionRequest', res.data);
      });
    },
  },
  mutations: {
    GET_REQUESTSLIST: (state, payload) => {
      state.requestsList = payload;
      return state.requestsList;
    },
    GET_REQUESTS: (state, payload) => {
      state.requests = payload;
      return state.requests;
    },
  },
  getters: {
    requestsList(state) {
      return state.requestsList;
    },
    requests(state) {
      return state.requests;
    },
  },
};
