import '@/plugins/axios';
import router from '@/router/index';

const api = '/platform/api/v1/invitations/';

export default {
  state: {
    invitationList: {},
    invitation: {},
  },
  actions: {
    getInvitationList(context, { search, page }) {
      if (!!search || page > 1) {
        if (`${router.app._route.path}${!!search ? `?src=${search}&` : '?'}page=${page || 1}` != router.app._route.fullPath) {
          router.push(`${router.app._route.path}${!!search ? `?src=${search}&` : '?'}page=${page || 1}`);
        }
        return axios.get(`${api}${!!search ? `?src=${search}&` : '?'}page=${page - 1 || 0}`).then((res) => {
          context.commit('GET_INVITATIONLIST', res.data);
        });
      } else {
        if (router.app._route.path != router.app._route.fullPath) {
          router.push(`${router.app._route.path}`);
        }
        return axios.get(`${api}?page=${page - 1 || 0}`).then((res) => {
          context.commit('GET_INVITATIONLIST', res.data);
        });
      }
    },
    setInvitationList: (context) => {
      context.commit('GET_INVITATIONLIST', {});
    },
    createInvitation(context, payload) {
      return axios.post(api, payload).then((res) => {
        router.push(`/invitation/${res.data.id}`);
      });
    },
    getInvitationItem(context) {
      return axios.get(`${api}${router.app._route.params.invitationId}`).then((res) => {
        context.commit('GET_INVITATION', res.data);
      });
    },
    setInvitationItem: (context) => {
      context.commit('GET_INVITATION', {});
    },
    removeInvitationItem(context) {
      return axios.delete(`${api}${router.app._route.params.invitationId}`).then(() => {
        router.push('/invitation');
      });
    },
  },
  mutations: {
    GET_INVITATIONLIST: (state, payload) => {
      state.invitationList = payload;
      return state.invitationList;
    },
    GET_INVITATION: (state, payload) => {
      state.invitation = payload;
      return state.invitation;
    },
  },
  getters: {
    invitationList(state) {
      return state.invitationList;
    },
    invitation(state) {
      return state.invitation;
    },
  },
};
