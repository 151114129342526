import '@/plugins/axios';
import router from '@/router/index';

const api = '/platform/api/v1/gift-vouchers/';

export default {
  state: {
    vouchersList: {},
    voucher: { product_order: {}, person: {}, receiver: {} },
    voucherAttachments: [],
  },
  actions: {
    getVouchersList(context, { search, page, status, sort }) {
      if (!!search || page > 1 || status != 'all' || !!sort.length) {
        if (
          `${router.app._route.path}${!!search ? `?src=${search}&` : '?'}${status != 'all' ? `status=${status}&` : ''}${
            !!sort.length ? `sort=${sort}&` : ''
          }page=${page || 1}` != router.app._route.fullPath
        ) {
          router.push(
            `${router.app._route.path}${!!search ? `?src=${search}&` : '?'}${status != 'all' ? `status=${status}&` : ''}${
              !!sort.length ? `sort=${sort}&` : ''
            }page=${page || 1}`
          );
        }
        const sortValue = [];
        for (var i = 0; i < sort.length; i++) {
          sortValue.push(`sort=${sort[i]}&`);
        }
        return axios
          .get(
            `${api}?${!!search ? `src=${search}&` : ''}${status != 'all' ? `status=${status}&` : ''}${sortValue.join('') || ''}page=${
              page - 1 || 0
            }`
          )
          .then((res) => {
            context.commit('GET_VOUCHERSLIST', res.data);
          });
      } else {
        if (router.app._route.path != router.app._route.fullPath) {
          router.push(`${router.app._route.path}`);
        }
        return axios.get(`${api}?page=${page - 1 || 0}`).then((res) => {
          context.commit('GET_VOUCHERSLIST', res.data);
        });
      }
    },
    setVouchersList: (context) => {
      context.commit('GET_VOUCHERSLIST', {});
    },
    getVoucherItem(context) {
      return axios.get(`${api}${router.app._route.params.voucherId}`).then((res) => {
        context.commit('GET_VOUCHER', res.data);
      });
    },
    setVoucherItem: (context) => {
      context.commit('GET_VOUCHER', { product_order: {}, person: {}, receiver: {} });
    },
    getVoucherItemAttachments(context) {
      return axios.get(`${api}${router.app._route.params.voucherId}/attachments/`).then((res) => {
        context.commit('GET_VOUCHERATTACHMENTS', res.data);
      });
    },
    updateVoucherItemAttachments(context, payload) {
      return axios.post(`${api}${router.app._route.params.voucherId}/attachments/`, payload).then(() => {
        context.dispatch('getVoucherItemAttachments');
      });
    },
    removeVoucherItemAttachments(context, payload) {
      return axios.delete(`${api}${router.app._route.params.voucherId}/attachments/${payload}`).then(() => {
        context.dispatch('getVoucherItemAttachments');
      });
    },
    setVoucherItemAttachments: (context) => {
      context.commit('GET_VOUCHERATTACHMENTS', []);
    },
  },
  mutations: {
    GET_VOUCHERSLIST: (state, payload) => {
      state.vouchersList = payload;
      return state.vouchersList;
    },
    GET_VOUCHER: (state, payload) => {
      state.voucher = payload;
      return state.voucher;
    },
    GET_VOUCHERATTACHMENTS: (state, payload) => {
      state.voucherAttachments = payload;
      return state.voucherAttachments;
    },
  },
  getters: {
    vouchersList(state) {
      return state.vouchersList;
    },
    voucher(state) {
      return state.voucher;
    },
    voucherAttachments(state) {
      return state.voucherAttachments;
    },
  },
};
