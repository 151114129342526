import '@/plugins/axios';
import router from '@/router/index';

const api = '/platform/api/v1/reviews/';

export default {
  state: {
    reviewList: { resilt: [] },
    review: {},
  },
  actions: {
    getReviewList(context, { type, page }) {
      if (!!type || page > 1) {
        if (`${router.app._route.path}${!!type ? type : '?'}page=${page || 1}` != router.app._route.fullPath) {
          router.push(`${router.app._route.path}${!!type ? type : '?'}page=${page || 1}`);
        }
        return axios.get(`${api}${!!type ? type : '?'}page=${page - 1 || 0}`).then((res) => {
          context.commit('GET_REVIEWLIST', res.data);
        });
      } else {
        if (router.app._route.path != router.app._route.fullPath) {
          router.push(`${router.app._route.path}`);
        }
        return axios.get(`${api}${!!type ? type : '?'}page=${page - 1 || 0}`).then((res) => {
          context.commit('GET_REVIEWLIST', res.data);
        });
      }
    },
    setReviewList: (context) => {
      context.commit('GET_REVIEWLIST', { resilt: [] });
    },
    createReview(context, payload) {
      return axios({
        method: 'post',
        url: `${api}`,
        data: payload,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    getReviewItem(context, payload) {
      return axios.get(`${api}${payload}`).then((res) => {
        context.commit('GET_REVIEW', res.data);
      });
    },
    setReviewItem: (context, payload) => {
      context.commit('GET_REVIEW', payload);
    },
    updateReviewItem(context, { data, id }) {
      return axios({
        method: 'put',
        url: `${api}${id}`,
        data: data,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    deleteReview(context, payload) {
      return axios.delete(`${api}${payload}`);
    },
  },
  mutations: {
    GET_REVIEWLIST: (state, payload) => {
      state.reviewList = payload;
      return state.reviewList;
    },
    GET_REVIEW: (state, payload) => {
      state.review = payload;
      return state.review;
    },
  },
  getters: {
    reviewList(state) {
      return state.reviewList;
    },
    review(state) {
      return state.review;
    },
  },
};
