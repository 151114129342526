import '@/plugins/axios';
import router from '@/router/index';

const api = '/platform/api/v1/';

export default {
  actions: {
    signIn(context, payload) {
      return axios.post(`${api}sign-in/email`, payload);
    },
    signInOtp(context, payload) {
      return axios.post(`${api}sign-in/otp`, payload);
    },
    signInRecovery(context, payload) {
      return axios.post(`${api}sign-in/otp-recovery`, payload);
    },
    reqResetPassword(context, payload) {
      return axios.post(`${api}request-password-reset`, payload);
    },
    resetPassword(context, payload) {
      return axios.post(`${api}password-reset`, payload);
    },
    emailConfirm(context, payload) {
      return axios.post(`${api}confirm-email`, payload);
    },
    refresh() {
      return axios
        .post(`${api}refresh`)
        .then((response) => {
          localStorage.setItem('jwt_token', response.data.access_token);
        })
        .catch(() => {
          localStorage.clear();
          // const loginpath = window.location.pathname;
          router.replace('/login');
        });
    },
  },
};
