<template>
  <v-list flat color="white" class="py-5 menu-list">
    <v-list-item
      @click="closeGroup"
      link
      v-show="['admin', 'manager', 'marketing', 'agent', 'trip_organizer', 'product_vendor'].some((item) => item == role)"
      to="/"
      class="font-weight-medium pl-8 pr-6"
      active-class="active"
      :class="'/' == $route.path ? 'active' : ''"
    >
      <v-list-item-icon class="my-3 mr-3">
        <v-img height="20" max-width="20" :src="getImage('dash')"></v-img>
      </v-list-item-icon>
      <v-list-item-content class="py-2">
        <v-list-item-title>Dashboard</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-group v-model="activeVendor" no-action v-show="['product_vendor'].some((item) => item == role)" class="font-weight-medium">
      <template v-slot:activator>
        <v-list-item class="pr-0 pl-4">
          <v-list-item-icon class="my-3 mr-3">
            <v-img height="20" max-width="20" :src="getImage('house')"></v-img>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Vendor</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-list-item
        v-for="i in itemsVendors"
        :key="i.title"
        link
        v-show="i.access.some((item) => item == role)"
        :to="`/${i.link}`"
        class="font-weight-medium pl-17 pr-6"
        active-class="active"
        :class="i.link.split('/')[1] == $route.path.split('/')[1] ? 'active' : ''"
      >
        <v-list-item-title>{{ i.title }}</v-list-item-title>
      </v-list-item>
    </v-list-group>
    <v-list-item
      @click="closeGroup"
      link
      v-show="['admin'].some((item) => item == role)"
      to="/customers"
      class="font-weight-medium pl-8 pr-6"
      active-class="active"
      :class="'/customers' == $route.path ? 'active' : ''"
    >
      <v-list-item-icon class="my-3 mr-3">
        <v-img height="20" max-width="20" :src="getImage('customers')"></v-img>
      </v-list-item-icon>
      <v-list-item-content class="py-2">
        <v-list-item-title>Customers</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item
      @click="closeGroup"
      link
      v-show="['admin', 'manager', 'agent'].some((item) => item == role)"
      to="/customer-requests"
      class="font-weight-medium pl-8 pr-6"
      active-class="active"
      :class="'customer-requests' == $route.path.split('/')[1] ? 'active' : ''"
    >
      <v-list-item-icon class="my-3 mr-3">
        <v-img height="20" max-width="20" :src="getImage('call')"></v-img>
      </v-list-item-icon>
      <v-list-item-content class="py-2">
        <v-list-item-title>Customer requests</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item
      @click="closeGroup"
      link
      v-show="['admin', 'manager', 'agent', 'trip_organizer'].some((item) => item == role)"
      to="/trips"
      class="font-weight-medium pl-8 pr-6"
      active-class="active"
      :class="'/trips' == $route.path ? 'active' : ''"
    >
      <v-list-item-icon class="my-3 mr-3">
        <v-img height="20" max-width="20" :src="getImage('airplane')"></v-img>
      </v-list-item-icon>
      <v-list-item-content class="py-2">
        <v-list-item-title>Trips</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item
      @click="closeGroup"
      link
      v-show="['admin', 'manager', 'agent'].some((item) => item == role)"
      to="/trips-requests"
      class="font-weight-medium pl-8 pr-6"
      active-class="active"
      :class="'/trips-requests' == $route.path ? 'active' : ''"
    >
      <v-list-item-icon class="my-3 mr-3">
        <v-img height="20" max-width="20" :src="getImage('requests')"></v-img>
      </v-list-item-icon>
      <v-list-item-content class="py-2">
        <v-list-item-title>Trips Requests</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item
      @click="closeGroup"
      link
      v-show="['admin', 'manager', 'agent'].some((item) => item == role)"
      to="/experiences"
      class="font-weight-medium pl-8 pr-6"
      active-class="active"
      :class="'/experiences' == $route.path ? 'active' : ''"
    >
      <v-list-item-icon class="my-3 mr-3">
        <v-img height="20" max-width="20" :src="getImage('trips')"></v-img>
      </v-list-item-icon>
      <v-list-item-content class="py-2">
        <v-list-item-title>Experiences</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item
      @click="closeGroup"
      link
      v-show="['admin', 'manager', 'agent'].some((item) => item == role)"
      to="/experts"
      class="font-weight-medium pl-8 pr-6"
      active-class="active"
      :class="'/experts' == $route.path ? 'active' : ''"
    >
      <v-list-item-icon class="my-3 mr-3">
        <v-img height="20" max-width="20" :src="getImage('star')"></v-img>
      </v-list-item-icon>
      <v-list-item-content class="py-2">
        <v-list-item-title>Experts</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item
      @click="closeGroup"
      link
      v-show="['admin', 'manager', 'agent'].some((item) => item == role)"
      to="/invitation"
      class="font-weight-medium pl-8 pr-6"
      active-class="active"
      :class="'/invitation' == $route.path ? 'active' : ''"
    >
      <v-list-item-icon class="my-3 mr-3">
        <v-img height="20" max-width="20" :src="getImage('invitation')"></v-img>
      </v-list-item-icon>
      <v-list-item-content class="py-2">
        <v-list-item-title>Invitation</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-group
      @click="(activeContent = false), (active = false), (activeReport = false), (activeGift = false)"
      v-model="activeBooking"
      no-action
      v-show="['admin', 'manager', 'agent', 'trip_organizer'].some((item) => item == role)"
      class="font-weight-medium"
    >
      <template v-slot:activator>
        <v-list-item class="pr-0 pl-4">
          <v-list-item-icon class="my-3 mr-3">
            <v-img height="20" max-width="20" :src="getImage('tripBook')"></v-img>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Bookings</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-list-item
        v-for="i in itemsBooking"
        :key="i.title"
        link
        v-show="i.access.some((item) => item == role)"
        :to="`/${i.link}`"
        class="font-weight-medium pl-17 pr-6"
        active-class="active"
        :class="i.link.split('/')[0] == $route.path.split('/')[1] ? 'active' : ''"
      >
        <v-list-item-title>{{ i.title }}</v-list-item-title>
      </v-list-item>
    </v-list-group>
    <v-list-item
      @click="closeGroup"
      link
      v-show="['admin', 'manager'].some((item) => item == role)"
      to="/payments"
      class="font-weight-medium pl-8 pr-6"
      active-class="active"
      :class="'/payments' == $route.path ? 'active' : ''"
    >
      <v-list-item-icon class="my-3 mr-3">
        <v-img height="20" max-width="20" :src="getImage('pay')"></v-img>
      </v-list-item-icon>
      <v-list-item-content class="py-2">
        <v-list-item-title>Payments</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-group
      @click="(activeContent = false), (active = false), (activeBooking = false), (activeGift = false)"
      v-model="activeReport"
      no-action
      v-show="['admin'].some((item) => item == role)"
      class="font-weight-medium"
    >
      <template v-slot:activator>
        <v-list-item class="pr-0 pl-4">
          <v-list-item-icon class="my-3 mr-3">
            <v-img height="20" max-width="20" :src="getImage('report')"></v-img>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Report</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-list-item
        v-for="i in itemsReport"
        :key="i.title"
        link
        v-show="i.access.some((item) => item == role)"
        :to="`/${i.link}`"
        class="font-weight-medium pl-17 pr-6"
        active-class="active"
        :class="i.link.split('/')[0] == $route.path.split('/')[1] ? 'active' : ''"
      >
        <v-list-item-title>{{ i.title }}</v-list-item-title>
      </v-list-item>
    </v-list-group>
    <v-list-item
      @click="closeGroup"
      link
      v-show="['admin'].some((item) => item == role)"
      to="/staff"
      class="font-weight-medium pl-8 pr-6"
      active-class="active"
      :class="'/staff' == $route.path ? 'active' : ''"
    >
      <v-list-item-icon class="my-3 mr-3">
        <v-img height="20" max-width="20" :src="getImage('staff')"></v-img>
      </v-list-item-icon>
      <v-list-item-content class="py-2">
        <v-list-item-title>Staff</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-group
      @click="(activeContent = false), (active = false), (activeBooking = false), (activeReport = false)"
      v-model="activeGift"
      no-action
      v-show="['admin', 'manager', 'marketing'].some((item) => item == role)"
      class="font-weight-medium"
    >
      <template v-slot:activator>
        <v-list-item class="pr-0 pl-4">
          <v-list-item-icon class="my-3 mr-3">
            <v-img height="20" max-width="20" :src="getImage('gift')"></v-img>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Gift vouchers</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-list-item
        v-for="i in itemsGift"
        :key="i.title"
        link
        v-show="i.access.some((item) => item == role)"
        :to="`/${i.link}`"
        class="font-weight-medium pl-17 pr-6"
        active-class="active"
        :class="i.link.split('/')[0] == $route.path.split('/')[1] ? 'active' : ''"
      >
        <v-list-item-title>{{ i.title }}</v-list-item-title>
      </v-list-item>
    </v-list-group>
    <v-list-item
      @click="closeGroup"
      link
      v-show="['admin', 'marketing'].some((item) => item == role)"
      to="/announcements"
      class="font-weight-medium pl-8 pr-6"
      active-class="active"
      :class="'/announcements' == $route.path ? 'active' : ''"
    >
      <v-list-item-icon class="my-3 mr-3">
        <v-img height="20" max-width="20" :src="getImage('bellG')"></v-img>
      </v-list-item-icon>
      <v-list-item-content class="py-2">
        <v-list-item-title>Announcements</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-group
      @click="(activeContent = false), (activeBooking = false), (activeReport = false), (activeGift = false)"
      v-model="active"
      no-action
      v-show="['admin', 'manager'].some((item) => item == role)"
      class="font-weight-medium"
    >
      <template v-slot:activator>
        <v-list-item class="pr-0 pl-4">
          <v-list-item-icon class="my-3 mr-3">
            <v-img height="20" max-width="20" :src="getImage('wallet')"></v-img>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Loyalty Programs</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-list-item
        v-for="i in itemsLoyatly"
        :key="i.title"
        link
        v-show="i.access.some((item) => item == role)"
        :to="`/${i.link}`"
        class="font-weight-medium pl-17 pr-6"
        active-class="active"
        :class="i.link.split('/')[0] == $route.path.split('/')[1] ? 'active' : ''"
      >
        <v-list-item-title>{{ i.title }}</v-list-item-title>
      </v-list-item>
    </v-list-group>
    <v-list-item
      @click="closeGroup"
      link
      v-show="['admin', 'manager', 'marketing'].some((item) => item == role)"
      to="/promotions"
      class="font-weight-medium pl-8 pr-6"
      active-class="active"
      :class="'/promotions' == $route.path ? 'active' : ''"
    >
      <v-list-item-icon class="my-3 mr-3">
        <v-img height="20" max-width="20" :src="getImage('promotions')"></v-img>
      </v-list-item-icon>
      <v-list-item-content class="py-2">
        <v-list-item-title>Promotions</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <!-- <v-list-item
      @click="closeGroup"
      link
      v-show="['admin'].some((item) => item == role)"
      to="/content-management/saudi-surprise"
      class="font-weight-medium pl-8 pr-6"
      active-class="active"
      :class="'content-management' == $route.path.split('/')[1] ? 'active' : ''"
    >
      <v-list-item-icon class="my-3 mr-3">
        <v-img height="20" max-width="20" :src="getImage('content')"></v-img>
      </v-list-item-icon>
      <v-list-item-content class="py-2">
        <v-list-item-title>Content management</v-list-item-title>
      </v-list-item-content>
    </v-list-item> -->
    <v-list-group
      @click="(active = false), (activeBooking = false), (activeReport = false), (activeGift = false)"
      v-model="activeContent"
      no-action
      v-show="['admin', 'manager', 'agent'].some((item) => item == role)"
      class="font-weight-medium"
    >
      <template v-slot:activator>
        <v-list-item class="pr-0 pl-4">
          <v-list-item-icon class="my-3 mr-3">
            <v-img height="20" max-width="20" :src="getImage('content')"></v-img>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Content</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-list-item
        v-for="i in itemsContent"
        :key="i.title"
        link
        v-show="i.access.some((item) => item == role)"
        :to="`/${i.link}`"
        class="font-weight-medium pl-17 pr-6"
        active-class="active"
        :class="i.link.split('/')[0] == $route.path.split('/')[1] ? 'active' : ''"
      >
        <v-list-item-title>{{ i.title }}</v-list-item-title>
      </v-list-item>
    </v-list-group>
    <v-list-item
      @click="closeGroup"
      link
      v-show="['admin', 'manager', 'marketing'].some((item) => item == role)"
      to="/reviews"
      class="font-weight-medium pl-8 pr-6"
      active-class="active"
      :class="'/reviews' == $route.path ? 'active' : ''"
    >
      <v-list-item-icon class="my-3 mr-3">
        <v-img height="20" max-width="20" :src="getImage('reviews')"></v-img>
      </v-list-item-icon>
      <v-list-item-content class="py-2">
        <v-list-item-title>Reviews</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item
      @click="closeGroup"
      link
      v-show="['admin'].some((item) => item == role)"
      to="/settings/trip-types"
      class="font-weight-medium pl-8 pr-6"
      active-class="active"
      :class="'settings' == $route.path.split('/')[1] ? 'active' : ''"
    >
      <v-list-item-icon class="my-3 mr-3">
        <v-img height="20" max-width="20" :src="getImage('setting')"></v-img>
      </v-list-item-icon>
      <v-list-item-content class="py-2">
        <v-list-item-title>Settings</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <!-- <v-list-item
      v-for="item in items"
      :key="item.title"
      link
      v-show="item.access.some((item) => item == role)"
      :to="`/${item.link}`"
      class="font-weight-medium pl-8 pr-6"
      active-class="active"
      :class="item.link.split('/')[0] == $route.path.split('/')[1] ? 'active' : ''"
    >
      <v-list-item-icon class="my-3 mr-3">
        <v-img height="20" max-width="20" :src="getImage(item.icon)"></v-img>
      </v-list-item-icon>
      <v-list-item-content class="py-2">
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item> -->
  </v-list>
</template>

<script>
export default {
  data() {
    return {
      // items: [
      //   { title: 'Dashboard', link: '', icon: 'dash', access: ['admin', 'manager', 'marketing', 'agent'] },
      //   { title: 'Customers', link: 'customers', icon: 'customers', access: ['admin'] },
      //   { title: 'Trips', link: 'trips', icon: 'trips', access: ['admin', 'manager', 'agent'] },
      //   { title: 'Trips Requests', link: 'trips-requests', icon: 'requests', access: ['admin', 'manager', 'agent'] },
      //   { title: 'Invitation', link: 'invitation', icon: 'invitation', access: ['admin', 'manager', 'agent'] },
      //   { title: 'Trip Bookings', link: 'trip-bookings', icon: 'tripBook', access: ['admin', 'manager', 'agent'] },
      //   { title: 'Payments', link: 'payments', icon: 'pay', access: ['admin', 'manager'] },
      //   { title: 'Staff', link: 'staff', icon: 'staff', access: ['admin'] },
      //   { title: 'Announcements', link: 'announcements', icon: 'bellG', access: ['admin', 'marketing'] },
      //   {
      //     title: 'Loyalty Programs',
      //     icon: 'wallet',
      //     access: ['admin', 'manager'],
      //     items: [
      //       { title: 'Mokafaa', link: 'mokafaa', access: ['admin', 'manager'] },
      //       { title: 'Digital wallet', link: 'digital-wallet', access: ['admin', 'manager'] },
      //       { title: 'Refill codes', link: 'refill-codes', access: ['admin'] },
      //     ],
      //   },
      //   { title: 'Promotions', link: 'promotions', icon: 'promotions', access: ['admin', 'manager', 'marketing'] },
      //   { title: 'Content management', link: 'content-management/saudi-surprise', icon: 'content', access: ['admin'] },
      //   { title: 'Settings', link: 'settings/trip-types', icon: 'setting', access: ['admin'] },
      // ],
      active: false,
      activeContent: false,
      activeBooking: false,
      activeReport: false,
      activeGift: false,
      activeVendor: false,
      itemsVendors: [
        { title: 'General', link: 'vendor/general', access: ['product_vendor'] },
        { title: 'Delivery', link: 'vendor/delivery', access: ['product_vendor'] },
        { title: 'Products', link: 'vendor/products', access: ['product_vendor'] },
        { title: 'Orders', link: 'vendor/orders', access: ['product_vendor'] },
      ],
      itemsBooking: [
        { title: 'Trips', link: 'trip-bookings', access: ['admin', 'manager', 'agent', 'trip_organizer'] },
        { title: 'Experiences', link: 'experience-bookings', access: ['admin', 'manager', 'agent'] },
      ],
      itemsReport: [{ title: 'VAT', link: 'vat', access: ['admin'] }],
      itemsGift: [
        { title: 'Offers', link: 'gift-voucher-offers', access: ['admin', 'manager', 'marketing'] },
        { title: 'Vouchers', link: 'gift-vouchers', access: ['admin', 'manager', 'marketing'] },
        { title: 'Vendors', link: 'gift-vendors', access: ['admin', 'manager'] },
      ],
      itemsLoyatly: [
        { title: 'Digital wallet', link: 'digital-wallet', access: ['admin', 'manager'] },
        { title: 'Mokafaa', link: 'mokafaa', access: ['admin', 'manager'] },
        { title: 'ANB', link: 'anb', access: ['admin', 'manager'] },
        { title: 'Discount codes', link: 'trip-discount-codes', access: ['admin'] },
        { title: 'Refill codes', link: 'refill-codes', access: ['admin'] },
      ],
      itemsContent: [
        { title: 'Saudi Surprise', link: 'saudi-surprise', access: ['admin', 'manager', 'agent'] },
        { title: 'Accommodations', link: 'accommodations', access: ['admin', 'manager', 'agent'] },
        { title: 'Traveler types', link: 'traveler-types', access: ['admin', 'manager'] },
        { title: 'Trip activities', link: 'trip-activities', access: ['admin', 'manager'] },
        { title: 'Trip groups', link: 'trip-groups', access: ['admin', 'manager'] },
        { title: 'Inspirations', link: 'inspirations', access: ['admin', 'manager'] },
      ],
    };
  },
  methods: {
    getImage(icon) {
      return require(`@/assets/icon/${icon}.svg`);
    },
    closeGroup() {
      this.active = false;
      this.activeContent = false;
      this.activeBooking = false;
      this.activeReport = false;
      this.activeGift = false;
      this.activeVendor = false;
    },
  },
  computed: {
    role() {
      return this.$store.getters.profile.role;
    },
  },
};
</script>

<style lang="scss">
#app .menu-list {
  .pl-17 {
    padding-left: 68px !important;
  }
  .v-list-item {
    min-height: 42px;
    height: 42px;
    color: var(--v-text-base) !important;
  }
  .v-list-item:hover {
    background: var(--v-gray-lighten3);
  }
  .v-list-group__header__append-icon {
    min-width: 20px !important;
  }
  .active {
    color: var(--v-black-base) !important;
    background: var(--v-primary-lighten3) !important;
    .v-image {
      filter: brightness(0);
    }
  }
  .active::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    width: 10px;
    height: 42px;
    background: var(--v-primary-base);
    border-radius: 0px 4px 4px 0px;
  }
}
</style>
