<template>
  <div class="main-layout">
    <Header />
    <div class="d-flex">
      <notifications></notifications>
      <Menu class="d-none d-lg-block" />
      <router-view class="content-view" />
    </div>
    <!-- <Footer /> -->
  </div>
</template>

<script>
import Header from '@/components/Header';
import Menu from '@/components/Menu';
// import Footer from '@/components/Footer';

export default {
  components: {
    // Footer,
    Header,
    Menu,
  },
};
</script>

<style>
.main-layout .content-view {
  padding: 20px 40px 50px 30px;
  max-width: 1655px;
  width: 100%;
  margin: 0 auto;
}
/* @media screen and (max-width: 999px) {
  .main-layout .content-view {
    padding-left: 40px;
    padding-right: 40px;
  }
} */
@media screen and (max-width: 1263px) {
  .main-layout .content-view {
    padding: 20px 20px 30px;
  }
}
@media screen and (max-width: 400px) {
  .main-layout .content-view {
    padding: 12px 12px 20px;
  }
}
</style>
