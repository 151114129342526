import '@/plugins/axios';
import router from '@/router/index';

const api = '/platform/api/v1/announcements/';

export default {
  state: {
    announcementsList: {},
    announcements: {},
  },
  actions: {
    getAnnouncementsList(context, payload) {
      if (payload > 1) {
        if (`${router.app._route.path}?page=${payload || 1}` != router.app._route.fullPath) {
          router.push(`${router.app._route.path}?page=${payload || 1}`);
        }
        return axios.get(`${api}?page=${payload - 1 || 0}`).then((res) => {
          context.commit('GET_ANNOOUNCEMENTSLIST', res.data);
        });
      } else {
        if (router.app._route.path != router.app._route.fullPath) {
          router.push(`${router.app._route.path}`);
        }
        return axios.get(`${api}`).then((res) => {
          context.commit('GET_ANNOOUNCEMENTSLIST', res.data);
        });
      }
    },
    setAnnouncementsList: (context) => {
      context.commit('GET_ANNOOUNCEMENTSLIST', {});
    },
    createAnnouncementsItem(context, payload) {
      return axios.post(`${api}`, payload).then((res) => {
        router.push(`/announcements/${res.data.id}`);
      });
    },
    getAnnouncementsItem(context) {
      return axios.get(`${api}${router.app._route.params.announcementsId}`).then((res) => {
        context.commit('GET_ANNOOUNCEMENTS', res.data);
      });
    },
    cancelAnnouncementsItem(context, payload) {
      return axios.post(`${api}${payload}/cancel`).then((res) => {
        context.commit('GET_ANNOOUNCEMENTS', res.data);
      });
    },
    setAnnouncementsItem: (context) => {
      context.commit('GET_ANNOOUNCEMENTS', {});
    },
  },
  mutations: {
    GET_ANNOOUNCEMENTSLIST: (state, payload) => {
      state.announcementsList = payload;
      return state.announcementsList;
    },
    GET_ANNOOUNCEMENTS: (state, payload) => {
      state.announcements = payload;
      return state.announcements;
    },
  },
  getters: {
    announcementsList(state) {
      return state.announcementsList;
    },
    announcements(state) {
      return state.announcements;
    },
  },
};
