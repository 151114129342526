'use strict';

import Vue from 'vue';
import axios from 'axios';
import store from './../store/index';
import router from './../router/index';

axios.defaults.headers.common['X-Auth-Token'] = `jwt ${localStorage.getItem('jwt_token')}`;

const config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('jwt_token');

    if (token) {
      config.headers['X-Auth-Token'] = `jwt ${token}`;
    }

    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  function(error) {
    // Do something with response error
    if (error.response.status == 401) {
      if (router.app._route.path != '/login') {
        store.dispatch('refresh');
        error.response.config.headers['X-Auth-Token'] = `${localStorage.getItem('jwt_token')}`;
        return axios(error.response.config);
      }
    }
    return Promise.reject(error);
  }
);

Plugin.install = function(Vue, options) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      },
    },
    $axios: {
      get() {
        return _axios;
      },
    },
  });
};

Vue.use(Plugin);

export default Plugin;
