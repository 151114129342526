<template>
  <v-card tile min-width="265" width="265" color="white" class="card">
    <MenuList />
  </v-card>
</template>

<script>
import MenuList from './MenuList.vue';

export default {
  components: {
    MenuList,
  },
};
</script>

<style scoped>
.card {
  /* margin-top: -60px; */
  min-height: calc(100vh - 60px);
}
</style>
